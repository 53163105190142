import { Component, ContentChild, Input, OnChanges, OnInit, TemplateRef } from '@angular/core';
import { BaseTableComponent } from '../base-table/base-table.component';
import { TableBaseData } from '../../table.model';
import { slideAnimation } from '@railmybox/shared/util';

@Component({
  selector: 'eg-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: ['./card-table.component.scss'],
  animations: [slideAnimation],
})
export class CardTableComponent<T extends TableBaseData> extends BaseTableComponent<T> implements OnChanges {
  @ContentChild('emptyTableTemplate') emptyTableTemplate: TemplateRef<any>;

  @ContentChild('expandContentTemplate') expandContentTemplate: TemplateRef<any>;

  @Input() expanded: number;
  totals: any = {};

  ngOnChanges() {
    if (this.showTotal) {
      this.calculateTotals();
    }
  }

  calculateTotals() {
    this.totals = {};
    if (this.dataSource?.length > 0) {
      this.columns.forEach((column) => {
        if (column.total) {
          this.totals[column.key] = this.dataSource.reduce((sum, row) => {
            const value = row[column.key];
            return sum + (typeof value === 'number' ? value : value && typeof value === 'object' ? value['amount'] : 0);
          }, 0);
        }
      });
    }
  }

  toggleExpansion(event: MouseEvent, idx: number): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
    if (this.expanded === idx) {
      this.expanded = undefined;
    } else {
      this.expanded = idx;
    }
  }
}
