<eg-input
  [name]="name"
  autocomplete="off"
  class="input"
  [disabled]="disabled"
  [prefixInputPadding]="20"
  [fieldType]="fieldType"
  [label]="labelWithSuffix"
  [placeholder]="placeholder"
  [value]="value || null"
  (changed)="inputChanged($event)"
>
</eg-input>

@if (control?.errors && errorMessage) {
<eg-form-error [errorMessage]="errorMessage" class="input-error"></eg-form-error>
}
