import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { UiModule } from '@eg/ui';
import { SharedPipesModule } from '@railmybox/shared/pipes';
import { TrainCardComponent } from './train-card.component';
import { CapacityPillModule } from '../capacity-pill';

@NgModule({
  declarations: [TrainCardComponent],
  imports: [CommonModule, TranslocoModule, UiModule, SharedPipesModule, FlexLayoutModule, CapacityPillModule],
  exports: [TrainCardComponent],
})
export class TrainCardModule {}
