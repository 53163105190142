import { getTableColumns, TableColumn } from '@eg/ui';
import { ChangeEntryModel } from '@railmybox/api-booking';
import { formatDateTime } from '@railmybox/shared/util';
import { translate } from '@ngneat/transloco';
import { HistoryTabComponent } from '.';

export function getHistoryTabsTableColumns(this: HistoryTabComponent): TableColumn<ChangeEntryModel>[] {
  return getTableColumns<ChangeEntryModel>([
    {
      title: `support.history.table.time`,
      translateColumnTitle: true,
      dataClass: 'text-standard',
      flex: 17,
      key: 'time',
      columnType: 'dynamic-text',
      valueGetterFn: (entity) => formatDateTime(this.lang, 'date-time', entity.time),
    },
    {
      title: `support.history.table.object`,
      translateColumnTitle: true,
      dataClass: 'text-strong',
      flex: 10,
      key: 'objectName',
      columnType: 'dynamic-text',
      valueGetterFn: (entity) => translate(`${this.prefix}.${entity.objectName}._object`),
    },
    {
      title: `support.history.table.field`,
      translateColumnTitle: true,
      dataClass: 'text-strong',
      flex: 20,
      key: 'field',
      columnType: 'dynamic-text',
      valueGetterFn: (entity) => (entity.field ? translate(`${this.prefix}.${entity.objectName}.${entity.field}`) : ' '),
    },
    {
      title: `support.history.table.oldValue`,
      translateColumnTitle: true,
      dataClass: 'text-standard',
      flex: 25,
      key: 'oldValue',
      columnType: 'text',
    },
    {
      title: `support.history.table.newValue`,
      translateColumnTitle: true,
      sortable: true,
      dataClass: 'text-standard',
      flex: 25,
      key: 'newValue',
      columnType: 'text',
    },
    {
      title: `support.history.table.username`,
      translateColumnTitle: true,
      dataClass: 'text-standard',
      flex: 15,
      key: 'username',
      columnType: 'text',
    },
  ]);
}
