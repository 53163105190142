import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { PillComponent } from './pill.component';
import { TranslocoModule } from '@jsverse/transloco';
import { SharedPipesModule } from '@railmybox/shared/pipes';

@NgModule({
  declarations: [PillComponent],
  imports: [CommonModule, FlexLayoutModule, TranslocoModule, SharedPipesModule],
  exports: [PillComponent],
})
export class PillModule {}
