import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { ControlContainer, FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap } from 'rxjs/operators';
import { CustomerModel, CustomerService } from '@railmybox/api-user';

@Component({
  selector: 'rmb-customer-select',
  templateUrl: './customer-select.component.html',
  styleUrls: ['./customer-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(CustomerSelectComponent)],
  standalone: false,
})
export class CustomerSelectComponent extends AbstractValueAccessorComponent<CustomerModel> implements OnInit {
  customers$: Observable<CustomerModel[]>;
  searchControl: FormControl = new FormControl(undefined);
  selectedName: string;
  @Input() filterCustomers: string;
  @Input() companyOwner: boolean = undefined;
  @Input() clearable = true;
  @Input() customerList: CustomerModel[] = [];

  constructor(
    private customerService: CustomerService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    const selectedCustomers = this.customerList.map((x) => x.id);
    this.customers$ = this.searchControl.valueChanges.pipe(
      switchMap((query: string) => (query ? this.customerService.listCustomers(this.companyOwner, query) : of([]))),
      map((x) => x.filter((item) => item.active === true)),
      map((customers: CustomerModel[]) =>
        this.filterCustomers
          ? customers.filter((customer) =>
              `${customer.id} ${customer.companyName}`.toLowerCase().includes(this.filterCustomers.toLowerCase())
            )
          : customers
      ),
      map((customer: CustomerModel[]) => customer.filter((search) => !selectedCustomers.includes(search.id))),

      untilDestroyed(this)
    );
  }

  onSearchValueChanged(query: string): void {
    this.searchControl.patchValue(query);
  }

  onSelect(model: CustomerModel): void {
    this.selectedName = model?.companyName;
    this.value = model;
    this.onChange(model);
    this.changed.emit(model);
  }

  async writeValue(value: CustomerModel): Promise<void> {
    super.writeValue(value);
    if (value) {
      const customer = await this.customerService.showCustomer(typeof value === 'string' ? value : value?.id).toPromise();

      // In case no entry found with that id
      if (!customer) {
        return;
      }

      this.value = customer;
      this.selectedName = customer.companyName;
    } else {
      this.value = undefined;
      this.selectedName = undefined;
    }
  }
}
