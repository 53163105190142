import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { PortAdminModel, PortService } from '@railmybox/api-booking';
import { ControlContainer, FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'rmb-port-select',
  templateUrl: './port-select.component.html',
  styleUrls: ['./port-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(PortSelectComponent)],
  standalone: false,
})
export class PortSelectComponent extends AbstractValueAccessorComponent<string> implements OnInit {
  ports$: Observable<PortAdminModel[]>;
  searchControl: FormControl = new FormControl(undefined);
  selectedName: string;
  @Input() filterPorts: string;
  @Input() clearable = false;

  constructor(
    private portService: PortService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.ports$ = this.searchControl.valueChanges.pipe(
      switchMap((query: string) => (query ? this.portService.listPorts(undefined, query) : of([]))),
      map((ports: PortAdminModel[]) =>
        this.filterPorts ? ports.filter((line) => `${line.id} ${line.name}`.toLowerCase().includes(this.filterPorts.toLowerCase())) : ports
      ),
      untilDestroyed(this)
    );
  }

  onSearchValueChanged(query: string): void {
    this.searchControl.patchValue(query);
  }

  onSelect(port: PortAdminModel): void {
    this.selectedName = port?.name;
    this.value = port?.id;
    this.onChange(port?.id);
    this.changed.emit(port?.id);
  }

  async writeValue(value: string): Promise<void> {
    super.writeValue(value);

    if (!value) {
      return;
    }
    const port = await this.portService
      .listPorts(undefined, value)
      .toPromise()
      .then((filteredValues) => filteredValues.find((port) => port.id === value));

    // In case no entry found with that id
    if (!port) {
      return;
    }

    this.value = port.id;
    this.selectedName = port.name;
  }
}
