import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalRef } from '@eg/ui';

@Component({
  selector: 'rmb-unsaved-changes-warning',
  templateUrl: './unsaved-changes-warning.component.html',
  styleUrl: './unsaved-changes-warning.component.scss',
})
export class UnsavedChangesWarningComponent {
  @Input() source: string;
  @Output() response = new EventEmitter<boolean>();

  constructor(private modalRef: ModalRef) {}

  onCloseClick(): void {
    this.modalRef.close();
  }

  onResponseClick(response: string): void {
    if (response === 'yes') {
      this.response.emit(true);
    }
    if (response === 'cancel') {
      this.response.emit(false);
    }
    this.modalRef.close(response);
  }
}
