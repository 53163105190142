import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { UiModule } from '@eg/ui';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslocoModule } from '@jsverse/transloco';
import { SharedUtilModule } from '@railmybox/shared/util';
import { CustomerListComponent } from './customer-list.component';

@NgModule({
  declarations: [CustomerListComponent],
  imports: [CommonModule, AngularSvgIconModule.forRoot(), UiModule, FlexLayoutModule, TranslocoModule, SharedUtilModule],
  exports: [CustomerListComponent],
})
export class CustomerListModule {}
