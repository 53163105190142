import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { VeterinaryStationModel, VeterinaryStationService } from '@railmybox/api-booking';
import { ControlContainer, FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs/operators';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';

@Component({
  selector: 'rmb-veterinary-station-select',
  templateUrl: './veterinary-station-select.component.html',
  styleUrls: ['./veterinary-station-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(VeterinaryStationSelectComponent)],
  standalone: false,
})
export class VeterinaryStationSelectComponent extends AbstractValueAccessorComponent<string> implements OnInit {
  veterinaryStations$: Observable<VeterinaryStationModel[]>;
  searchControl: FormControl = new FormControl<string | null>(undefined);
  selectedName: string;
  @Input() clearable = false;

  constructor(
    private veterinaryStationService: VeterinaryStationService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.veterinaryStations$ = this.searchControl.valueChanges.pipe(
      switchMap((query: string) => (query ? this.veterinaryStationService.listVeterinaryStations(query) : of([]))),
      untilDestroyed(this)
    );
  }

  onSearchValueChanged(query: string): void {
    this.searchControl.patchValue(query);
  }

  onSelect(vetStation: VeterinaryStationModel): void {
    this.selectedName = vetStation?.name;
    this.value = vetStation?.id;
    this.onChange(vetStation?.id);
    this.changed.emit(vetStation?.id);
  }

  markInputAsTouched(): void {
    this.onTouched();
    this.blurred.emit();
  }

  async writeValue(value: string): Promise<void> {
    super.writeValue(value);

    if (!value) {
      return;
    }

    const veterinaryOffice = await this.veterinaryStationService
      .listVeterinaryStations(value)
      .toPromise()
      .then((filteredValues) => filteredValues.find((vetStation) => vetStation.id === value));

    if (!veterinaryOffice) {
      return;
    }

    this.value = veterinaryOffice.id;
    this.selectedName = veterinaryOffice.name;
  }
}
