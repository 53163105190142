import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailVerificationComponent } from './email-verification.component';
import { TranslocoModule } from '@jsverse/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '@eg/ui';

@NgModule({
  declarations: [EmailVerificationComponent],
  imports: [CommonModule, TranslocoModule, FlexLayoutModule, ReactiveFormsModule, UiModule],
})
export class EmailVerificationModule {}
