import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { HistoryTabComponent } from './history-tab.component';
import { UiModule } from '@eg/ui';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [HistoryTabComponent],
  imports: [CommonModule, FlexLayoutModule, UiModule, TranslocoModule],
  exports: [HistoryTabComponent],
})
export class HistoryTabModule {}
