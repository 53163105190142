import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { ControlContainer } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgxMaskOptions } from 'ngx-mask';
import { FieldPrefixDirective, FieldSuffixDirective } from '@railmybox/shared/directive';

@Component({
  selector: 'eg-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(InputComponent)],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: false,
})
export class InputComponent extends AbstractValueAccessorComponent<string | number> implements OnInit {
  @Input() type = 'text';
  @Input() autocomplete?: string;
  @Input() prefixInputPadding = 10;
  @Input() numberMask = 'separator.2';
  inputSeparator: NgxMaskOptions['decimalMarker'];
  decimalMarker: NgxMaskOptions['decimalMarker'];

  @Output() keyDown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

  @ContentChild(FieldPrefixDirective, { read: ElementRef }) set prefix(prefix: ElementRef) {
    if (prefix) {
      this.hasPrefixIcon = !!prefix.nativeElement;
      this.prefixContainerWidth = prefix.nativeElement.offsetWidth < 80 ? prefix.nativeElement.offsetWidth : 80;
    }
  }

  @ContentChild(FieldSuffixDirective, { read: ElementRef }) set suffix(suffix: ElementRef) {
    if (suffix) {
      this.hasSuffixIcon = !!suffix.nativeElement;
    }
  }

  hasPrefixIcon = false;
  hasSuffixIcon = false;
  prefixContainerWidth?: number;

  constructor(
    private translocoService: TranslocoService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.translocoService.langChanges$.subscribe((lang) => {
      this.inputSeparator = lang === 'de' ? '.' : ',';
      this.decimalMarker = lang === 'de' ? ',' : '.';
      this.changeDetectorRef.detectChanges();
    });
  }

  /**
   * Event handler when input is change.
   */
  inputChanged(): void {
    this.onChange(this.value);
    this.formControl?.setValue(this.value);
    this.changed.emit(this.value);
  }

  handleKeyDown(event: KeyboardEvent) {
    this.keyDown.emit(event);
  }

  handleKeyUp(event: any) {
    this.onChange(event.target.value);
    this.formControl?.setValue(event.target.value);
    this.changed.emit(event.target.value);
  }

  onNumberKeyup(event: any) {
    this.onChange(this.value);
    this.formControl?.setValue(this.value);
    this.changed.emit(this.value);
  }
}
