import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { resetPassword, confirmResetPassword } from '@aws-amplify/auth';
import { passwordValidator } from '@railmybox/shared/util';
import { AuthQuery, AuthStoreService } from '../../store/auth';

@Component({
  selector: 'rmb-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  standalone: false,
})
export class ResetPasswordComponent {
  readonly TRANSLOCO_PREFIX = 'auth.resetpassword.form';

  resetPasswordForm: FormGroup;
  errorMessage: string;
  counter: string;
  passwordSubmitted = false;
  showCodeResent = false;
  formError = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authQuery: AuthQuery,
    private authStoreService: AuthStoreService
  ) {
    this.resetPasswordForm = this.getPasswordResetForm();
  }

  /**
   * resends verification code
   */
  async resendCode(): Promise<void> {
    this.errorMessage = '';
    const username = this.authQuery.getUserEmail();
    try {
      resetPassword({ username }).then(() => {
        this.showCodeResent = true;
      });
    } catch (err) {
      this.errorMessage = typeof err === 'string' ? err : err.message;
    }
  }

  /**
   * Checks if two form controls have the same value
   */
  matching(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ match: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  /**
   * sets new password
   */
  async onSubmit(): Promise<void> {
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.errorMessage = '';
    const { code, password } = this.resetPasswordForm.value;

    const username = this.authQuery.getUserEmail();
    confirmResetPassword({ username: username, newPassword: password, confirmationCode: code })
      .then(() => {
        this.passwordSubmitted = true;
        this.authStoreService.updateUserCredentials({ password: password });
        this.authStoreService.updateUIState({ destination: 'login' });

        this.router.navigate(['/auth', 'login']);
      })
      .catch((err) => {
        this.errorMessage = typeof err === 'string' ? err : err.message;
        this.formError = true;
      });
  }

  /**
   * retrieves the reset password form
   * @private
   */
  private getPasswordResetForm(): FormGroup {
    return this.formBuilder.group(
      {
        code: ['', [Validators.required]],
        password: ['', [passwordValidator]],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: this.matching('password', 'confirmPassword'),
      }
    );
  }
}
