import { Component, Input } from '@angular/core';
import { DispoProblemModel, ProblemStatusTypeModel } from '@railmybox/api-dispo';

@Component({
  selector: 'rmb-problem-card',
  templateUrl: './problem-card.component.html',
  styleUrls: ['./problem-card.component.scss'],
  standalone: false,
})
export class ProblemCardComponent {
  @Input() problem: DispoProblemModel = {
    status: ProblemStatusTypeModel.Open,
    containerId: 'CAXU1234567',
    root: 'DAMAGES',
    group: 'electronic_phytosanitary_certificate_is_required',
    opened: Date.now().toString(),
  };
}
