import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Breadcrumb } from './breadcrumb.interface';

@Component({
  selector: 'eg-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: false,
})
export class BreadcrumbComponent {
  @Input() breadcrumbs: Breadcrumb[] = [];
  @Input() breadcrumbPadding = '248px';
  @Input() selected: string;
  @Output() breadcrumbSelected: EventEmitter<Breadcrumb> = new EventEmitter<Breadcrumb>();

  breadcrumbClicked(breadcrumb: Breadcrumb): void {
    if (this.selected === breadcrumb.key) {
      return;
    }

    this.selected = breadcrumb.key;
    this.breadcrumbSelected.emit(breadcrumb);
  }
}
