import { Component, EventEmitter, Input, Output } from '@angular/core';
import { dispoStatusColors } from '../color-pills-consts';
import { PillColorType } from '@eg/ui';
import { DispoOrderInfoModel } from '@railmybox/api-dispo';

@Component({
  selector: 'rmb-dispo-order-chain',
  templateUrl: './dispo-order-chain.component.html',
  styleUrls: ['./dispo-order-chain.component.scss'],
  standalone: false,
})
export class DispoOrderChainComponent {
  @Input() reference?: string = '';
  @Input() dispoOrder: DispoOrderInfoModel;

  @Output() clicked = new EventEmitter<DispoOrderInfoModel>();

  dispoStatusColors: PillColorType = dispoStatusColors;

  goToOrder(dispoOrder: DispoOrderInfoModel) {
    this.clicked.emit(dispoOrder);
  }
}
