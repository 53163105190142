<div class="py-15" *transloco="let translate">
  <ul class="navigation-list">
    @for (item of items; track item) {
    <li>
      <a
        class="navigation-list--link"
        (click)="onLinkClick(item.value)"
        [routerLinkActive]="['active']"
        [ngClass]="{ active: item.value === activeItem }"
        [routerLink]="item.value"
      >
        {{ translate('support.subNavigation.' + item.label) }}
      </a>
    </li>
    }
  </ul>
</div>
