import dayjs from 'dayjs/esm';
import utc from 'dayjs/esm/plugin/utc';
import timezone from 'dayjs/esm/plugin/timezone';
import minMax from 'dayjs/esm/plugin/minMax';
import duration from 'dayjs/esm/plugin/duration';
import { TimeZoneSelect, timezonesWithGMT } from './timezones';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(minMax);
dayjs.extend(duration);

export class DateRangeType {
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
}

export function calculateDateRange(
  railTransportDate: string,
  isExport: boolean,
  isCustoms: boolean,
  isDangerousGoods: boolean,
  arrivalDate: string,
  terminalCutOffTime?: string
): DateRangeType {
  const shipmentDate = dayjs(railTransportDate);
  const trainArrival = dayjs(arrivalDate);

  const trainDay = trainArrival.day();
  const isSunday = trainDay === 0;
  const isSaturday = trainDay === 6;

  // For export everything is easy. You can deliver up to 3 days in advance until the terminal cutoff
  if (isExport) {
    const minDate = shipmentDate.startOf('day').add(isDangerousGoods ? -1 : -3, 'day');
    let maxDate = shipmentDate.endOf('day'); // TODO Cutoff
    if (terminalCutOffTime) {
      const [hours, minutes] = terminalCutOffTime.split(':').map(Number);
      // Latest delivery is 6 hours before terminal cutoff
      maxDate = shipmentDate.hour(hours).minute(minutes).second(0).subtract(6, 'hours');
    }
    return { minDate, maxDate };
  } else {
    // DEFAULT: minDate option is 8 hours after train arrival
    let minDate = trainArrival.add(8, 'hour');

    // if train arrival is the day after shipment, set to 7 AM next day
    if (trainArrival.date() - shipmentDate.date() == 1) {
      minDate = trainArrival.startOf('day').add(1, 'day').add(7, 'hour');
    }

    // if the train arrives on a weekend, move to Monday 1 PM
    if (isSaturday || isSunday) {
      minDate = trainArrival.add(1, 'week').day(1).startOf('day').add(13, 'hour');
    }

    let maxDate;

    if (isCustoms) {
      maxDate = trainArrival.add(7, 'day').endOf('day');
    } else if (isDangerousGoods) {
      maxDate = trainArrival.add(4, 'day').endOf('day');
    } else {
      maxDate = undefined;
    }

    // ensure maxDate is never before minDate
    if (maxDate && maxDate.isBefore(minDate)) {
      maxDate = minDate.endOf('day');
    }

    return { minDate, maxDate };
  }
}

export function guessTimezone(): string {
  return dayjs.tz.guess();
}

export function getTimeZoneList(): TimeZoneSelect[] {
  return timezonesWithGMT;
}
