<div *transloco="let translate" class="header">
  <img src="/assets/icons/btn_prev.svg" alt="back" (click)="back()" class="pointer" />

  <div fxFlex="100" fxLayout="column" fxLayoutAlign="flex-start">
    @if (edit) {
    <h2 class="subtitle-h2 mx-30 my-0">{{ title }}</h2>
    } @else {
    <h2 class="subtitle-h2 mx-30 my-0">{{ createHeader }}</h2>
    } @if (subtitle) {
    <h3 class="subtitle-h3 mx-30 my-1">{{ subtitle }}</h3>
    } @if (secondarySubtitle) {
    <h3 class="subtitle-h3 mx-30 my-1">{{ secondarySubtitle }}</h3>
    }
  </div>

  <div fxFlex="100" fxLayoutAlign="end center" fxLayout="row">
    <ng-content></ng-content>
    @if (showSaveButton) {
    <div fxFlex fxLayoutAlign="end center" fxLayout="row wrap" fxLayoutGap="8px">
      <button
        egButton
        class="px-44 eg-button__round__saving"
        egType="round"
        egButtonSize="small"
        (click)="save()"
        [disabled]="saveDisabled"
        [loading]="loading"
      >
        {{ loading ? translate('action.saving') : translate('support.button.save') }}
      </button>
    </div>
    }
  </div>
</div>
