import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormErrorModule, SelectModule } from '@eg/ui';
import { CreditorSelectComponent } from './creditor-select.component';

@NgModule({
  declarations: [CreditorSelectComponent],
  imports: [CommonModule, SelectModule, TranslocoModule, FlexLayoutModule, FormErrorModule],
  exports: [CreditorSelectComponent],
})
export class CreditorSelectModule {}
