import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ModalService } from '@eg/ui';
import { UnsavedChangesWarningComponent } from '@railmybox/ui';
import { Observable, map } from 'rxjs';

export interface CanDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

export const unsavedChangesGuard: CanDeactivateFn<CanDeactivate> = (component: CanDeactivate) => {
  const modal = inject(ModalService);

  if (component?.canDeactivate && !component.canDeactivate()) {
    const modalRef = modal.open({
      title: '',
      showHeader: false,
      width: '400px',
      content: UnsavedChangesWarningComponent,
      data: { prefix: 'support', source: 'unsavedChanges' },
      backdropClose: false,
    });

    return modalRef.afterClosed$.pipe(
      map((result) => {
        if (result.data === 'yes') {
          return true;
        } else {
          return false;
        }
      })
    );
  } else {
    return true;
  }
};
