import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { SelectItem } from '@eg/ui';
import { DangerousGoodsUnnoModel } from '@railmybox/api-booking';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';

@Component({
  selector: 'rmb-technical-name-select',
  templateUrl: './technical-name-select.component.html',
  styleUrls: ['./technical-name-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(TechnicalNameSelectComponent)],
  standalone: false,
})
export class TechnicalNameSelectComponent extends AbstractValueAccessorComponent<DangerousGoodsUnnoModel> implements OnInit, OnChanges {
  @Input() technicalNames: DangerousGoodsUnnoModel[];
  _technicalNames: SelectItem[] | SelectItem;

  constructor(protected changeDetectorRef: ChangeDetectorRef, @Optional() _controlContainer: ControlContainer) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.mapTechnicalNamesItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.mapTechnicalNamesItems();
  }

  mapTechnicalNamesItems() {
    this._technicalNames = this.technicalNames?.map((el) => {
      return { label: this.createName(el), value: el } as SelectItem;
    });
  }

  onSelect(technicalName: DangerousGoodsUnnoModel): void {
    this.value = technicalName;
    this.onChange(technicalName);
    this.changed.emit(technicalName);
  }

  async writeValue(value: DangerousGoodsUnnoModel): Promise<void> {
    if (!value) {
      return;
    }
    super.writeValue(value);
    this.value = value;
  }

  private createName(el: DangerousGoodsUnnoModel) {
    let name = el.name;
    if (el.classId) {
      name += ` - ${formatClassId(el)}`;
    }
    if (el.packingGroup) name += ` - ${el.packingGroup}`;
    return name;
  }
}

export function formatClassId(unno: DangerousGoodsUnnoModel): string {
  return unno.secondaryClass1 || unno.secondaryClass2
    ? unno.classId + ' (' + ((unno.secondaryClass1 || '') + ' ' + (unno.secondaryClass2 || '')).trim() + ')'
    : unno.classId;
}
