import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddressModel, AddressTypeModel, OfferRequestModel, TemplateModel } from '@railmybox/api-booking';

@Component({
  selector: 'rmb-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss'],
  standalone: false,
})
export class TemplateCardComponent {
  private _template: TemplateModel;
  private firstAddress: AddressModel;
  private secondAddress: AddressModel;
  private thirdAddress: AddressModel;

  @Input() enableEditing = true;
  @Output() templateClicked: EventEmitter<OfferRequestModel> = new EventEmitter<OfferRequestModel>();
  @Output() templateEditClicked: EventEmitter<TemplateModel> = new EventEmitter<TemplateModel>();
  @Output() templateDeleteClicked: EventEmitter<string> = new EventEmitter<string>();

  @Input() set template(template: TemplateModel) {
    this._template = template;
    const addresses = this._template?.addresses;

    const stationLoadpoints = addresses?.filter((el) => el.type === AddressTypeModel.Loadpoint || el.type === AddressTypeModel.Station);

    const locations = addresses?.filter((el) => el.type === AddressTypeModel.Location);

    this.firstAddress = stationLoadpoints[0];
    this.secondAddress = stationLoadpoints[stationLoadpoints.length - 1];

    this.thirdAddress = locations[locations.length - 1];
  }

  get template(): TemplateModel {
    return this._template;
  }

  readonly TRANSLOCO_PREFIX = 'dashboard.template';

  onEditClick(event: any) {
    event.stopPropagation();
    event.preventDefault();

    this.templateEditClicked.emit(this.template);
  }

  onDeleteClick(event: any) {
    event.stopPropagation();
    event.preventDefault();

    this.templateDeleteClicked.emit(this.template.id);
  }

  onTemplateSelect(event: any) {
    event.stopPropagation();
    event.preventDefault();

    this.templateClicked.emit({
      templateId: this.template.id,
      num20ftContainer: this.template.num20ftContainer,
      num40ftContainer: this.template.num40ftContainer,
      num45ftContainer: this.template.num45ftContainer,
      shipmentAddress: this.firstAddress,
      destinationAddress: this.secondAddress,
    });
  }
}
