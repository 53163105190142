/// <reference types="@types/googlemaps" />
import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IconSelect, SelectItem } from '@eg/ui';

@UntilDestroy()
@Component({
  selector: 'railmybox-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.scss'],
  standalone: false,
})
export class ComponentsComponent {
  radioSelectItems: SelectItem[] = [
    { label: 'item 1', value: 'i1', disabled: false },
    { label: 'item 2', value: 'i2', disabled: false },
    { label: 'item disabled', value: 'i3', disabled: true },
  ];

  tileSelectItems: SelectItem[] = [
    { label: 'item 1', value: 'i1', disabled: false },
    { label: 'item 2', value: 'i2', disabled: false },
    { label: 'item disabled', value: 'i3', disabled: true },
  ];

  iconSelectItems: IconSelect[] = [
    { iconName: 'trip_drop', value: true },
    { iconName: 'trip_eco', value: false },
  ];
}
