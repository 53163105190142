import { AfterViewInit, Component, ContentChild, ElementRef, HostListener, Input, OnChanges, TemplateRef, ViewChild } from '@angular/core';
import { BaseTableComponent } from '../base-table/base-table.component';
import { TableBaseData } from '../../table.model';
import { slideAnimation } from '@railmybox/shared/util';

@Component({
  selector: 'eg-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: ['./card-table.component.scss'],
  animations: [slideAnimation],
  standalone: false,
})
export class CardTableComponent<T extends TableBaseData> extends BaseTableComponent<T> implements OnChanges, AfterViewInit {
  @ContentChild('emptyTableTemplate') emptyTableTemplate: TemplateRef<any>;

  @ContentChild('expandContentTemplate') expandContentTemplate: TemplateRef<any>;

  @ViewChild('tableHeader', { static: false }) tableHeader: ElementRef;
  headerOffset = 0;

  @Input() expanded: number;
  totals: any = {};
  isScrolling = false;

  ngOnChanges() {
    if (this.showTotal) {
      this.calculateTotals();
    }
  }

  ngAfterViewInit(): void {
    this.headerOffset = this.tableHeader.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any): void {
    if (window.scrollY > this.headerOffset) {
      this.isScrolling = true;
    } else {
      this.isScrolling = false;
    }
  }

  calculateTotals() {
    this.totals = {};
    if (this.dataSource?.length > 0) {
      this.columns.forEach((column) => {
        if (column.total) {
          this.totals[column.key] = this.dataSource.reduce((sum, row) => {
            const value = row[column.key];
            return sum + (typeof value === 'number' ? value : value && typeof value === 'object' ? value['amount'] : 0);
          }, 0);
        }
      });
    }
  }

  toggleExpansion(event: MouseEvent, idx: number): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
    if (this.expanded === idx) {
      this.expanded = undefined;
    } else {
      this.expanded = idx;
    }
  }
}
