import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { UiModule } from '@eg/ui';
import { SubNavigationComponent } from './sub-navigation.component';

@NgModule({
  declarations: [SubNavigationComponent],
  imports: [CommonModule, TranslocoModule, FlexLayoutModule, UiModule],
  exports: [SubNavigationComponent],
})
export class SubNavigationModule {}
