import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SelectItem } from '../select.interface';

@Component({
  selector: 'eg-radio-select',
  templateUrl: './radio-select.component.html',
  styleUrls: ['./radio-select.component.scss'],
  standalone: false,
})
export class RadioSelectComponent {
  @Input() value: unknown;
  @Input() items: SelectItem[];
  @Input() translateLabel = true;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();

  onItemSelect(item: SelectItem) {
    if (this.value === item.value) {
      return;
    }

    this.value = item.value;
    this.valueChanged.emit(this.value as string);
  }
}
