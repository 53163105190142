import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fetchAuthSession, fetchUserAttributes, resetPassword, signIn } from '@aws-amplify/auth';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthStoreService, DeliveryMedium } from '../../store/auth';
import { UserStoreService } from '../../store/user';
import { CustomerStoreService } from '../../store/customer';
import { emailValidator } from '@railmybox/shared/util';

@UntilDestroy()
@Component({
  selector: 'rmb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: false,
})
export class LoginComponent implements OnInit {
  readonly TRANSLOCO_PREFIX = 'auth.login.form';
  loginForm: FormGroup;
  errorMessage: string;
  confirmationModal = false;
  confirmEmailForm: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authStoreService: AuthStoreService,
    private userStoreService: UserStoreService,
    private customerStoreService: CustomerStoreService
  ) {
    this.loginForm = this.getLoginForm();
  }

  ngOnInit() {
    this.confirmEmailForm = this.formBuilder.group({
      email: ['', [Validators.required, emailValidator]],
    });
  }

  /**
   * Logs the user in app
   *
   * In case of an error update the view
   */
  async login(): Promise<void> {
    if (this.loginForm.invalid) {
      return;
    }
    const { email, password } = this.loginForm.value;
    this.authStoreService.updateUserCredentials({ email });
    this.userStoreService.updateUserState({ email });

    try {
      const user = await signIn({ username: email, password });
      if (user.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        await this.router.navigate(['/auth', 'reset-password']);
      }

      const { tokens } = await fetchAuthSession({ forceRefresh: true });

      this.authStoreService.updateAuthState({
        tokens: {
          idToken: tokens?.idToken,
          accessToken: tokens?.accessToken,
        },
      });
      const attributes = await fetchUserAttributes();
      this.userStoreService.updateUserAttributes(attributes);
      const redirectPath = this.authStoreService.getAuthQuery().getRedirectPath();
      if (redirectPath) {
        this.authStoreService.setRedirectTo(undefined);
        await this.router.navigateByUrl(redirectPath);

        return;
      }

      const customer = await this.customerStoreService.initializeCustomerInfo();

      switch (customer.registrationStep) {
        case 'COMPANY':
          await this.router.navigate(['/profile', 'company-data']);
          break;
        case 'PAYMENT':
          await this.router.navigate(['/profile', 'payment']);
          break;
        default:
          await this.router.navigate(['/dashboard', 'bookings']);
          break;
      }
    } catch (err) {
      const errorMsg = typeof err === 'string' ? err : err.message;
      if (err.name === 'UserNotConfirmedException') {
        await this.router.navigate(['/auth', 'verify']);
      }

      if (errorMsg === 'Password reset required for the user') {
        await this.router.navigate(['/auth', 'reset-password']);
      }
      this.errorMessage = errorMsg;
    }
  }

  /**
   * Clears the error message
   */
  closeErrorMessage(): void {
    this.errorMessage = null;
  }

  /**
   * If user entered valid e-mail into login form email control field, it is filled into send reset code form
   */
  resetPasswordModal() {
    const email = this.loginForm.get('email');

    if (email.valid) {
      this.confirmEmailForm.patchValue({ email: email.value });
    }
    this.toggleConfirmModal();
  }

  /**
   * E-mail confirm modal switch
   */
  toggleConfirmModal() {
    this.confirmationModal = !this.confirmationModal;
  }

  /**
   * Navigates the user to reset password page
   */
  async onResetPassword(): Promise<void> {
    const email = this.confirmEmailForm.get('email');
    if (email.valid) {
      this.authStoreService.updateUserCredentials({
        email: email.value,
      });
      return await this.sendVerificationCode();
    }
  }

  /**
   * Makes an API call to send verification code (Only is email is valid)
   */
  async sendVerificationCode(): Promise<void> {
    try {
      this.authStoreService.updateUserCredentials({
        email: this.confirmEmailForm.get('email').value,
      });
      const resetPasswordResult = await resetPassword({ username: this.confirmEmailForm.get('email').value });
      const { deliveryMedium, destination } = resetPasswordResult.nextStep.codeDeliveryDetails;
      this.authStoreService.updateUIState({
        deliveryMedium: deliveryMedium === 'EMAIL' ? DeliveryMedium.EMAIL : null,
        destination: destination,
      });
      await this.router.navigate(['/auth', 'reset-password']);
    } catch (err) {
      this.errorMessage = typeof err === 'string' ? err : err.message;
    }
  }

  /**
   * Retrieves the login form
   * @private
   */
  private getLoginForm(): FormGroup {
    return this.formBuilder.group({
      email: ['', [Validators.required, emailValidator]],
      password: ['', [Validators.required]],
    });
  }
}
