import { Component, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Tab } from './tab.interface';
import { TabGroupService } from './service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'eg-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  standalone: false,
})
export class TabGroupComponent implements OnInit {
  @Input() translateLabels = false;
  @Output() tabSelected: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedTab: Tab;
  tabs$: Observable<Tab[]>;
  selectedTabId: string;

  constructor(private tabGroupService: TabGroupService) {
    this.tabs$ = this.tabGroupService.tabs$;
  }

  ngOnInit(): void {
    this.tabGroupService.selectedTab$.pipe(untilDestroyed(this)).subscribe({
      next: (tab) => {
        this.selectedTab = tab;
        this.selectedTabId = tab.tabId ? tab.tabId : tab.id;
        this.tabSelected.next(tab);
      },
    });
  }

  onTabClick(item: Tab): void {
    if (item?.tabId ? item.tabId === this.selectedTabId : item.id === this.selectedTabId || item.disabled) {
      return;
    }

    this.selectedTabId = item.tabId ? item.tabId : item.id;
    this.tabGroupService.selectTabById(item.tabId ? item.tabId : item.id);
  }
}
