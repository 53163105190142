import { Component, Input, OnInit } from '@angular/core';
import { CurrencyModel } from '@railmybox/api-pricing';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { SelectItem } from 'primeng/api';
import { map, Observable, Subject } from 'rxjs';

@Component({
  selector: 'rmb-currency-select',
  templateUrl: './currency-select.component.html',
  styleUrl: './currency-select.component.scss',
  providers: [...ValueAccessorUtil.getValueAccessorProvider(CurrencySelectComponent)],
  standalone: false,
})
export class CurrencySelectComponent extends AbstractValueAccessorComponent<string> implements OnInit {
  private _currencyList: CurrencyModel[] = [];

  @Input() set currencyList(currencies: CurrencyModel[]) {
    if (!currencies) {
      return;
    }
    this._currencyList = currencies;

    this.typeahead$.next('');
  }

  @Input() readonly = false;
  items$: Observable<SelectItem[]>;
  typeahead$ = new Subject<string | null>();

  ngOnInit() {
    this.items$ = this.typeahead$.pipe(
      map((term) => {
        return this.searchCurrency(term).map((currency) => ({
          label: currency.name,
          value: currency.alphaCode,
          symbol: currency.symbol || currency.alphaCode,
        }));
      })
    );
  }

  private searchCurrency(term: string | null): CurrencyModel[] {
    if (term) {
      return this._currencyList.filter((currency) => {
        term = term.toLowerCase();
        return currency.name.indexOf(term) > -1 || currency.alphaCode.toLowerCase().indexOf(term) > -1;
      });
    }

    return this._currencyList;
  }

  onValueSelect(value: string): void {
    this.onChange(value);
    this.changed.emit(value);
  }

  onBlur(): void {
    this.onTouched();
    this.blurred.emit();
  }
}
