import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingAccountInfoComponent } from './billing-account-info.component';
import { FlexModule } from '@ngbracket/ngx-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { CustomerService } from '@railmybox/api-user';
import { AlertModule, InputModule } from '@eg/ui';

@NgModule({
  declarations: [BillingAccountInfoComponent],
  imports: [CommonModule, InputModule, FlexModule, ReactiveFormsModule, TranslocoModule, AlertModule],
  providers: [
    CustomerService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: [{ scope: 'booking/billing', alias: 'billing' }],
    },
  ],
  exports: [BillingAccountInfoComponent],
})
export class BillingAccountInfoModule {}
