import { Directive, HostListener, Self } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'form[formGroup]',
  standalone: false,
})
export class MarkFormTouchedDirective {
  /**
   * Handles onSubmit logic to call mark all as touched
   */
  @HostListener('submit')
  onSubmit(): void {
    if (!this.container.control) {
      return;
    }
    this.container.control.markAllAsTouched();
    this.container.control.patchValue(this.container.control.value);
  }

  constructor(@Self() private container: ControlContainer) {}
}
