import dayjs from 'dayjs/esm';
import utc from 'dayjs/esm/plugin/utc';
import timezone from 'dayjs/esm/plugin/timezone';
import minMax from 'dayjs/esm/plugin/minMax';
import duration from 'dayjs/esm/plugin/duration';
import { TimeZoneSelect, timezonesWithGMT } from './timezones';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(minMax);
dayjs.extend(duration);

export class DateRangeType {
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
}

export function calculateDateRange(
  railTransportDate: string,
  isExport: boolean,
  isCustoms: boolean,
  isDangerousGoods: boolean,
  arrivalDate: string,
  terminalCutOffTime?: string
): DateRangeType {
  const shipmentDate = dayjs.utc(railTransportDate).local().startOf('hour');
  const trainArrival = dayjs.utc(arrivalDate).local().startOf('hour');

  const trainDay = trainArrival.day();
  const isSunday = trainDay === 0;
  const isSaturday = trainDay === 6;
  const isFriday = trainDay === 5;
  const isImport = !isExport;

  // DEFAULT: minDate option is 8 hours after train arrival
  let minDate = trainArrival.add(8, 'hour');

  // if train arrival is the day after shipment, set to 7 AM next day
  if (trainArrival.date() !== shipmentDate.date()) {
    minDate = trainArrival.startOf('day').add(1, 'day').add(7, 'hour');
  }

  // if the train arrives or minDate is on a weekend, move to Monday 1 PM
  if (isSaturday || isSunday || minDate.day() === 6 || minDate.day() === 0) {
    minDate = trainArrival.startOf('week').add(1, 'day').startOf('day').add(13, 'hour');
  }

  // Friday import set to Friday 18:00
  if (isFriday && isImport) {
    minDate = trainArrival.add(18, 'hour');
  }

  // if export and terminalCutOffTime it is 6 hours before terminal cut-off
  if (isExport && terminalCutOffTime) {
    const cutOffBase = trainArrival.startOf('day').local();

    const [hours, minutes] = terminalCutOffTime.split(':').map(Number);

    const cutOffTime = cutOffBase.hour(hours).minute(minutes).second(0);

    minDate = cutOffTime.subtract(6, 'hour');
  }

  let maxDate;

  if (isExport) {
    maxDate = trainArrival.endOf('day');
  } else if (isCustoms) {
    maxDate = trainArrival.add(7, 'day').endOf('day');
  } else if (isDangerousGoods) {
    maxDate = trainArrival.add(4, 'day').endOf('day');
  } else {
    maxDate = undefined;
  }

  // ensure maxDate is never before minDate
  if (maxDate && maxDate.isBefore(minDate)) {
    maxDate = minDate.endOf('day');
  }

  return { minDate, maxDate };
}

export function guessTimezone(): string {
  return dayjs.tz.guess();
}

export function getTimeZoneList(): TimeZoneSelect[] {
  return timezonesWithGMT;
}
