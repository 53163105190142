import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationComponent } from './confirmation.component';
import { TranslocoModule } from '@jsverse/transloco';
import { FlexModule } from '@ngbracket/ngx-layout';
import { ModalModule } from '../modal';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [ConfirmationComponent],
  imports: [CommonModule, TranslocoModule, FlexModule, AngularSvgIconModule.forRoot(), ModalModule],
  exports: [ConfirmationComponent],
})
export class ConfirmationModule {}
