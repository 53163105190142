import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { CpaFacilityModel, CPAFacilityService } from '@railmybox/api-booking';
import { ControlContainer, FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, switchMap } from 'rxjs/operators';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';

@UntilDestroy()
@Component({
  selector: 'rmb-cpa-facility-select',
  templateUrl: './cpa-facility-select.component.html',
  styleUrls: ['./cpa-facility-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(CpaFacilitySelectComponent)],
  standalone: false,
})
export class CpaFacilitySelectComponent extends AbstractValueAccessorComponent<CpaFacilityModel> implements OnInit {
  cpaFacilities$: Observable<CpaFacilityModel[]>;
  searchControl: FormControl = new FormControl(undefined);
  selectedName: string;
  @Input() clearable = false;

  @Output() addressSaved: EventEmitter<CpaFacilityModel['address']> = new EventEmitter<CpaFacilityModel['address']>();

  constructor(
    private cpaFacilityService: CPAFacilityService,
    changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.cpaFacilities$ = this.searchControl.valueChanges.pipe(
      switchMap((query: string) => (query ? this.cpaFacilityService.listCpaFacilities(query).pipe(catchError(() => of([]))) : of([]))),
      untilDestroyed(this)
    );
  }

  onSearchValueChanged(query: string): void {
    this.searchControl.patchValue(query);
  }

  onSelect(model: CpaFacilityModel): void {
    this.selectedName = model?.name;
    this.value = model;
    this.onChange(model);
    this.changed.emit(model);
  }

  async writeValue(value: CpaFacilityModel): Promise<void> {
    super.writeValue(value);

    if (!value || !value.name) {
      return;
    }

    this.value = value;
    this.selectedName = value.name;
  }
}
