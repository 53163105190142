import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SelectItem } from '@eg/ui';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'rmb-sub-navigation',
  templateUrl: './sub-navigation.component.html',
  styleUrls: ['./sub-navigation.component.scss'],
  standalone: false,
})
export class SubNavigationComponent implements OnInit {
  @Input() items: SelectItem[];
  @Output() clicked: EventEmitter<string> = new EventEmitter<string>();
  activeItem: string;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.setActiveItemFromRoute();
      });

    this.setActiveItemFromRoute();
  }

  setActiveItemFromRoute(): void {
    const currentUrl = this.router.url;
    this.activeItem = this.items?.find((item) => currentUrl.includes(item.value))?.value || '';
  }

  onLinkClick(value: string): void {
    this.clicked.emit(value);
  }
}
