<div *transloco="let translate" fxFlex="100" fxFlexFill fxLayout="column" fxLayoutAlign="center center">
  <div class="reg-content">
    <h1 class="title">{{ translate(TRANSLOCO_PREFIX + '.title') }}</h1>
    <form fxLayout="column" fxLayoutAlign="space-around space-between" [formGroup]="registrationForm" (ngSubmit)="registration()">
      @if (errorMessage) {
      <eg-alert fxFlex="100" type="error" [message]="errorMessage"></eg-alert>
      }

      <div fxFlex="100" fxLayout="column">
        <h3 class="subtitle-h3">{{ translate(TRANSLOCO_PREFIX + '.companyInformation') }}</h3>
        <div fxFlex="100" fxLayoutAlign="space-between start" fxLayout="row wrap">
          <eg-input
            fxFlex="33"
            type="text"
            name="companyName"
            formControlName="companyName"
            fieldType="M"
            [placeholder]="translate(TRANSLOCO_PREFIX + '.companyName')"
            [label]="translate(TRANSLOCO_PREFIX + '.companyName')"
          >
          </eg-input>

          <eg-input
            fxFlex="33"
            type="text"
            name="firstName"
            formControlName="firstName"
            fieldType="M"
            [placeholder]="translate(TRANSLOCO_PREFIX + '.firstName')"
            [label]="translate(TRANSLOCO_PREFIX + '.firstName')"
          >
          </eg-input>

          <eg-input
            fxFlex="33"
            type="text"
            name="lastName"
            formControlName="lastName"
            fieldType="M"
            [placeholder]="translate(TRANSLOCO_PREFIX + '.lastName')"
            [label]="translate(TRANSLOCO_PREFIX + '.lastName')"
          >
          </eg-input>
        </div>

        <h3 class="subtitle-h3 mt-36 mb-16">{{ translate(TRANSLOCO_PREFIX + '.companyAddress') }}</h3>
        <div fxFlex="100" fxLayoutAlign="space-between start" fxLayout="row wrap">
          <eg-input
            type="text"
            name="address"
            formControlName="address"
            rmbGoogleAutocomplete
            [countries]="[]"
            fieldType="M"
            [placeholder]="translate(TRANSLOCO_PREFIX + '.address')"
            [label]="translate(TRANSLOCO_PREFIX + '.address')"
            (changed)="addressChanged($event)"
            (placeChanged)="onPlaceChanged($event)"
          >
          </eg-input>
        </div>

        <h3 class="subtitle-h3 mt-36 mb-16">{{ translate(TRANSLOCO_PREFIX + '.account') }}</h3>

        <div fxFlex="100" fxLayoutAlign="space-between start" fxLayout="row wrap">
          <eg-input
            class="mb-15"
            fxFlex="49"
            type="email"
            name="email"
            formControlName="email"
            fieldType="M"
            [placeholder]="translate(TRANSLOCO_PREFIX + '.email')"
            [label]="translate(TRANSLOCO_PREFIX + '.email')"
          >
          </eg-input>

          <rmb-phone-number
            fxFlex="49"
            name="phone"
            formControlName="phoneNumber"
            phoneType="mobile"
            formRules="M"
            [label]="translate(TRANSLOCO_PREFIX + '.phoneNumber')"
            [placeholder]="translate(TRANSLOCO_PREFIX + '.phoneNumber')"
          ></rmb-phone-number>

          <eg-input
            fxFlex="49"
            type="password"
            name="password"
            formControlName="password"
            fieldType="M"
            [translationPrefix]="TRANSLOCO_PREFIX"
            [placeholder]="translate(TRANSLOCO_PREFIX + '.password')"
            [label]="translate(TRANSLOCO_PREFIX + '.password')"
          >
          </eg-input>

          <eg-input
            fxFlex="49"
            type="password"
            name="confirmPassword"
            formControlName="confirmPassword"
            fieldType="M"
            [translationPrefix]="TRANSLOCO_PREFIX"
            [placeholder]="translate(TRANSLOCO_PREFIX + '.confirmPassword')"
            [label]="translate(TRANSLOCO_PREFIX + '.confirmPassword')"
          >
          </eg-input>

          <div fxLayout="column" fxLayoutAlign="start">
            <eg-checkbox
              class="mt-36"
              formControlName="terms"
              [label]="translate(TRANSLOCO_PREFIX + '.terms')"
              [translationPrefix]="TRANSLOCO_PREFIX"
            ></eg-checkbox>

            <eg-checkbox
              class="mt-15"
              formControlName="privacy"
              [label]="translate(TRANSLOCO_PREFIX + '.privacy')"
              [translationPrefix]="TRANSLOCO_PREFIX"
            ></eg-checkbox>
          </div>
        </div>

        <div class="btn-container" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="btn-sidebar"></div>
          <button class="btn btn-submit" type="submit">
            {{ translate(TRANSLOCO_PREFIX + '.button.signUp') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
