<div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center" *transloco="let translate">
  <div fxFlex="100" class="train" fxLayout="column" fxLayoutGap="8px">
    <div fxFlex="100" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start">
      <div fxFlex class="train__id">{{ trainInfo?.trainIdOperator }}</div>
      <div fxFlex fxLayoutAlign="end center" fxLayoutGap="8px">
        <div>
          {{ trainInfo?.planTime ? translate(TRANSLOCO_PREFIX + '.plannedDeparture') : null }}
          {{ trainInfo?.planTime | egDate: 'date-time' }}
        </div>
        <div>
          {{ trainInfo?.arrivalTime ? translate(TRANSLOCO_PREFIX + '.plannedArrival') : null }}
          {{ trainInfo?.arrivalTime | egDate: 'date-time' }}
        </div>
      </div>
    </div>

    <div fxFlex="100" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <div fxFlex="20" fxLayoutAlign="start center">
        <eg-pill [value]="trainInfo?.trainStatus" translationPrefix="support.trainStatus." [colors]="trainStatusColors"></eg-pill>
      </div>
      <div fxFlex fxLayoutAlign="end center" class="train__text">
        {{ trainInfo?.startingAddressCode }} - {{ trainInfo?.destinationAddressCode }}
      </div>
    </div>

    <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex fxLayoutGap="8px" fxLayoutAlign="end center">
        <rmb-capacity-pill [train]="trainInfo" type="capacity"></rmb-capacity-pill>
        <rmb-capacity-pill [train]="trainInfo" type="weight"></rmb-capacity-pill>
      </div>
    </div>
    <div fxFlex="100" class="train__expand">
      {{ translate(TRANSLOCO_PREFIX + '.showContainers') }}
    </div>
  </div>
</div>
