import { Component, Input } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { SelectItem } from '../select.interface';

@Component({
  selector: 'eg-tile-select',
  templateUrl: './tile-select.component.html',
  styleUrls: ['./tile-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(TileSelectComponent)],
  standalone: false,
})
export class TileSelectComponent extends AbstractValueAccessorComponent<string | string[]> {
  @Input() options: SelectItem[] = [];
  @Input() alignment = 'center center';
  @Input() containerWidth = 506;
  @Input() multiple = true;
  @Input() tileGap = 11;
  @Input() translateAble = true;
  selected: Set<string> = new Set<string>();

  onSelectionChange(value: string): void {
    if (this.disabled) {
      return;
    }

    if (this.value === value && !this.multiple) {
      return;
    }

    this.onMultipleValueSelection(value);
    this.value = this.multiple ? Array.from(this.selected) : value;

    this.onChange(this.value);
    this.changed.emit(this.value);
    this.onTouched();
  }

  isSelected(option: SelectItem): boolean {
    if (!this.multiple) {
      return this.value === option.value;
    }

    return this.selected.has(option.value as string);
  }

  isUnselected(option: SelectItem): boolean {
    if (!this.multiple) {
      return this.value !== undefined && this.value !== null && this.value !== option.value;
    }

    return false;
  }

  onMultipleValueSelection(value: string): void {
    if (!this.multiple) {
      return;
    }

    if (this.selected.has(value)) {
      this.selected.delete(value);

      return;
    }

    this.selected.add(value);
  }

  writeValue(value: string | string[]) {
    if (this.multiple) {
      (value as string[])?.forEach((item) => this.selected.add(item));
    }
    super.writeValue(value);
  }
}
