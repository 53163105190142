import { Injectable } from '@angular/core';
import { CurrencyModel, CurrencyService } from '@railmybox/api-pricing';
import { Observable } from 'rxjs';

@Injectable()
export class ValueCurrencyService {
  constructor(private currencyService: CurrencyService) {}

  getCurrencyList(): Observable<CurrencyModel[]> {
    return this.currencyService.listCurrencies();
  }
}
