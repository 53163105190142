import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgSelectComponent, DropdownPosition } from '@ng-select/ng-select';
import { AddTagFn } from '@ng-select/ng-select/lib/ng-select.component';
import { Subject } from 'rxjs';
import { SelectItem } from '../select';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';

@Component({
  selector: 'eg-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(MultiselectComponent)],
  standalone: false,
})
export class MultiselectComponent extends AbstractValueAccessorComponent<string[]> {
  classes = 'block relative';
  @ViewChild('ngSelect', { static: true }) ngSelect: NgSelectComponent;
  @Input() items: SelectItem[];
  @Input() dropDownPosition: DropdownPosition = 'bottom';
  @Input() selectOnTab = true;
  @Input() clearable = false;
  @Input() editableSearchTerm = false;
  @Input() addTag: boolean | AddTagFn = false;
  @Input() bindValue: 'label' | 'value' = 'value';
  @Input() bindLabel = 'label';
  @Input() typeahead: Subject<string>;
  @Input() labelTemplate: TemplateRef<{ item: SelectItem }>;
  @Input() optionTemplate: TemplateRef<{ item: SelectItem }>;
  selectedItems = [];

  /**
   * Emits the value to parent component
   * If it is being used as a formControl then update the formValue
   */
  onSelectionChanged(value: SelectItem[]): void {
    this.value = value.map((el) => {
      return el.value as string;
    });
    if (value.length < 1) {
      this.value = null;
    }
    this.onChange(this.value);
    this.changed.emit(this.value);
  }

  /**
   * Marks the control as touched
   */
  onSelectTouched(): void {
    if (this.onTouched instanceof Function) {
      this.onTouched();
    }

    this.blurred.emit();
  }

  /**
   * Opens the select box even if label is clicked
   */
  onLabelClick(): void {
    this.ngSelect.open();
  }

  selectedOptionCheck(item: string) {
    return this.value?.includes(item);
  }
}
