import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { PageHeaderComponent } from './page-header.component';
import { UiModule } from '@eg/ui';

@NgModule({
  declarations: [PageHeaderComponent],
  imports: [CommonModule, TranslocoModule, FlexLayoutModule, UiModule],
  exports: [PageHeaderComponent],
})
export class PageHeaderModule {}
