import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractDateComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import dayjs from 'dayjs/esm';
import utc from 'dayjs/esm/plugin/utc';
import timezone from 'dayjs/esm/plugin/timezone';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Calendar } from 'primeng/calendar';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.extend(utc);
dayjs.extend(timezone);

export interface DatePickerValue {
  endDate: dayjs.Dayjs;
  startDate: dayjs.Dayjs;
}

export enum OutputType {
  datetime = 'datetime',
  date = 'date',
}

@UntilDestroy()
@Component({
  selector: 'eg-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(DateRangeComponent)],
})
export class DateRangeComponent
  extends AbstractDateComponent<{
    endDate: any;
    startDate: any;
  }>
  implements OnInit
{
  @Input() dateLimit: number;
  @Input() output: OutputType = OutputType.datetime;
  @Input() showClearButton = false;

  dates: Date[] = [];

  @ViewChild('dateRange') dateRange: Calendar;

  get formattedValue(): string {
    if (!this.value?.endDate || !this.value?.startDate) {
      return undefined;
    }

    return `${this.value.startDate.tz(dayjs.tz.guess(), true).format(this.localeFormat)} - ${this.value.endDate
      .tz(dayjs.tz.guess(), true)
      .format(this.localeFormat)}`;
  }

  /**
   * emits selected interval
   * @param value
   */
  onInputChanged(): void {
    const values = {
      startDate: this.dates[0] ? dayjs(this.dates[0].toISOString()) : undefined,
      endDate: this.dates[1] ? dayjs(this.dates[1].toISOString()).endOf('day') : undefined,
    };

    if (values.startDate && !values?.endDate && this.dateLimit) {
      this.minDateFormatted = values.startDate.toDate();
      this.maxDateFormatted = values.startDate.add(this.dateLimit, 'day').toDate();
    }

    if (!values?.endDate || !values?.startDate) {
      return;
    }

    this.value = values;
    this.onChange(this.getFormattedValue(values));
    this.changed.emit(this.getFormattedValue(values));
    this.minDateFormatted = this.defaultMinDate;
    this.maxDateFormatted = this.defaultMaxDate;
  }

  openDatePicker(event: MouseEvent): void {
    if (this.control?.disabled || this.disabled) {
      return;
    }

    this.dateRange.toggle();
    event.stopPropagation();
    event.preventDefault();
  }

  onDatepickerClosed(): void {
    if (this.dates) {
      if (!this.dates[0] || !this.dates[1]) {
        this.dateRange.clear();
        this.minDateFormatted = this.defaultMinDate;
        this.maxDateFormatted = this.defaultMaxDate;
      }
    }
  }

  override writeValue(value: { endDate: any; startDate: any }): void {
    if (value?.startDate && value?.endDate) {
      super.writeValue({
        endDate: dayjs(value?.endDate).tz(dayjs.tz.guess(), true),
        startDate: dayjs(value?.startDate).tz(dayjs.tz.guess(), true),
      });
      this.dates = [this.value.startDate.toDate(), this.value.endDate.toDate()];
    }

    if (value === null) {
      super.writeValue(value);
      this.dateRange.clear();
    }
  }

  override setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    if (isDisabled === this.control.disabled) {
      return;
    }
    const disableFn = isDisabled ? 'disable' : 'enable';
    this.control[disableFn]({ emitEvent: false });
  }

  getFormattedValue(value: DatePickerValue): any {
    let start: string;
    let end: string;

    switch (this.output) {
      case OutputType.datetime:
        start = value.startDate.tz(dayjs.tz.guess(), true).toISOString();
        end = value.endDate.tz(dayjs.tz.guess(), true).toISOString();
        break;
      case OutputType.date:
        start = value.startDate.tz(dayjs.tz.guess(), true).format('YYYY-MM-DD');
        end = value.endDate.tz(dayjs.tz.guess(), true).format('YYYY-MM-DD');
        break;
      default:
        start = value.startDate.tz(dayjs.tz.guess(), true).toISOString();
        end = value.endDate.tz(dayjs.tz.guess(), true).toISOString();
    }

    return { startDate: start, endDate: end };
  }
}
