import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@ngbracket/ngx-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UiModule } from '@eg/ui';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { MainComponent } from './main/main.component';
import { SupportNavComponent } from './main/support-nav/support-nav.component';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    UiModule,
    FlexModule,
    TranslocoModule,
    TieredMenuModule,
    PanelMenuModule,
    TooltipModule,
  ],
  declarations: [MainComponent, SupportNavComponent],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: [{ scope: 'support', alias: 'support' }],
    },
  ],
  exports: [MainComponent, SupportNavComponent],
})
export class LayoutModule {}
