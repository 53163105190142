import { ChangeDetectorRef, Component, Input, OnChanges, Optional, SimpleChanges } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { IsoCodeModel, ISOCodeService } from '@railmybox/api-booking';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'rmb-container-type-select',
  templateUrl: './container-type-select.component.html',
  styleUrls: ['./container-type-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(ContainerTypeSelectComponent)],
  standalone: false,
})
export class ContainerTypeSelectComponent extends AbstractValueAccessorComponent<string> implements OnChanges {
  isoCodes: IsoCodeModel[] = [];
  filteredValues: IsoCodeModel[] = [];
  @Input() length: number;
  @Input() supportRole = false;
  @Input() clearable = false;

  constructor(
    private isoCodeService: ISOCodeService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    super.ngOnChanges(changes);
    if (changes.length.currentValue) {
      this.isoCodes = await this.isoCodeService.listIsoCodes(this.supportRole ? null : this.length).toPromise();
      this.filteredValues = [...this.isoCodes];
    }
  }

  onSearchValueChanged(query: string): void {
    this.filteredValues = this.isoCodes.filter((code) => code.display.includes(query));
  }

  onSelect(isoCode: IsoCodeModel): void {
    this.value = isoCode?.code;
    this.onChange(isoCode?.code);
    this.changed.emit(isoCode?.code);
  }
}
