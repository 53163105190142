import dayjs from 'dayjs/esm';
import utc from 'dayjs/esm/plugin/utc';
import timezone from 'dayjs/esm/plugin/timezone';
import minMax from 'dayjs/esm/plugin/minMax';
import duration from 'dayjs/esm/plugin/duration';
import { TimeZoneSelect, timezonesWithGMT } from './timezones';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(minMax);
dayjs.extend(duration);

export class DateRangeType {
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
}

export function calculateDateRange(
  railTransportDate: string,
  isExport: boolean,
  isCustoms: boolean,
  isDangerousGoods: boolean
): DateRangeType {
  const isFriday = dayjs.utc(railTransportDate).day() == 5;
  const isSaturday = dayjs.utc(railTransportDate).day() == 6;
  const isImport = !isExport;

  let minDate = dayjs
    .utc(railTransportDate)
    .startOf('day')
    .add(!isExport ? 2 : isDangerousGoods ? -1 : -3, 'day');

  if (isExport) minDate = dayjs.max(minDate, dayjs.utc().startOf('hour').add(49, 'hour'));

  if (isFriday && isImport) {
    minDate = dayjs.utc(railTransportDate).startOf('hour').add(85, 'hour');
  }

  if (isSaturday && isImport) {
    minDate = dayjs.utc(railTransportDate).startOf('day').add(3, 'day');
  }

  let maxDate;

  if (isExport) {
    maxDate = dayjs(railTransportDate).endOf('day');
  } else if (isCustoms) {
    maxDate = dayjs.utc(railTransportDate).add(7, 'day').endOf('day');
  } else if (isDangerousGoods) {
    maxDate = dayjs.utc(railTransportDate).add(4, 'day').endOf('day');
  } else {
    maxDate = undefined;
  }

  return { minDate, maxDate };
}

export function guessTimezone(): string {
  return dayjs.tz.guess();
}

export function getTimeZoneList(): TimeZoneSelect[] {
  return timezonesWithGMT;
}
