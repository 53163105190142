<form [formGroup]="valueCurrencyForm" fxLayout="row wrap" fxFlex="100">
  <eg-input
    name="valueCurrency"
    formControlName="amount"
    autocomplete="off"
    type="number"
    class="value-currency"
    [fieldType]="formRule"
    [label]="labelWithSuffix"
    [placeholder]="placeholder"
    (blurred)="onBlur()"
  >
    <rmb-currency-select
      egFieldSuffixDirective
      class="select-currency"
      formControlName="currency"
      [currencyList]="currencyList$ | async"
      [readonly]="formRule === 'R'"
      (blurred)="onBlur()"
      [egTooltip]="tooltip"
    >
    </rmb-currency-select>
  </eg-input>
</form>
