import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[egDropdown]',
  exportAs: 'egDropdown',
  standalone: false,
})
export class DropdownDirective {
  @HostBinding('class.open') isOpen = false;

  constructor(private elementRef: ElementRef) {}

  @HostListener('click', ['$event'])
  toggleOpen(event: MouseEvent) {
    this.isOpen = !this.isOpen;
    event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  closeOutside(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }
}
