import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rmb-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  @Input() edit = true;
  @Input() loading: boolean;
  @Input() createHeader: string;
  @Input() title: string;
  @Input() subtitle?: string;
  @Input() secondarySubtitle?: string;
  @Input() showSaveButton = true;
  @Input() saveDisabled?: boolean = false;
  @Output() backClicked: EventEmitter<boolean> = new EventEmitter<false>();
  @Output() saveClicked: EventEmitter<void> = new EventEmitter<void>();

  save() {
    this.saveClicked.emit();
  }

  back() {
    this.backClicked.emit(true);
  }
}
