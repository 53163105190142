import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { supportMenuItems } from './support-items';

@Component({
  selector: 'rmb-support-nav',
  templateUrl: './support-nav.component.html',
  styleUrls: ['./support-nav.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: [{ scope: 'support', alias: 'support' }],
    },
  ],
  standalone: false,
})
export class SupportNavComponent implements OnInit {
  @Input() pricingOrAccountingRole: boolean;
  readonly TRANSLOCO_PREFIX = 'support.navigation';
  isExpanded: boolean;
  items = supportMenuItems;

  constructor(private elementRef: ElementRef) {}

  toggleSidebar() {
    this.isExpanded = !this.isExpanded;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isExpanded = false;
    }
  }

  ngOnInit() {
    this.items = supportMenuItems.filter((item) => !['.pricing', '.accounting'].includes(item.label) || this.pricingOrAccountingRole);
  }
}
