<div class="py-10" *transloco="let translate">
  @for (item of items; track item) {
  <ul class="navigation-list">
    <li>
      <a
        class="navigation-list--link"
        (click)="onLinkClick(item.value)"
        [routerLinkActive]="['active']"
        [ngClass]="{ active: item.value === activeItem }"
        [routerLink]="item.value"
      >
        {{ translate('support.subNavigation.' + item.label) }}
      </a>
    </li>
  </ul>
  }
</div>
