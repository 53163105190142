import { Component, Input } from '@angular/core';
import { PillColorType } from '@eg/ui';
import { trainStatusColors } from '../color-pills-consts';
import { TrainVisitInfoModel } from '@railmybox/api-dispo';

@Component({
  selector: 'rmb-train-card',
  templateUrl: './train-card.component.html',
  styleUrl: './train-card.component.scss',
  standalone: false,
})
export class TrainCardComponent {
  readonly TRANSLOCO_PREFIX = 'capacity-management.train';
  @Input() trainInfo: TrainVisitInfoModel;

  trainStatusColors: PillColorType = trainStatusColors;
}
