import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { ShippingLineModel, ShippingLineService } from '@railmybox/api-booking';
import { ControlContainer, FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap } from 'rxjs/operators';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';

@Component({
  selector: 'rmb-shipping-line-select',
  templateUrl: './shipping-line-select.component.html',
  styleUrls: ['./shipping-line-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(ShippingLineSelectComponent)],
  standalone: false,
})
export class ShippingLineSelectComponent extends AbstractValueAccessorComponent<string> implements OnInit {
  shippingLines$: Observable<ShippingLineModel[]>;
  searchControl: FormControl = new FormControl<string | null>(undefined);
  selectedName: string;
  @Input() filterShippingLines: string;
  @Input() clearable = false;

  constructor(
    private shippingLineService: ShippingLineService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.shippingLines$ = this.searchControl.valueChanges.pipe(
      switchMap((query: string) => (query ? this.shippingLineService.listShippingLines(query) : of([]))),
      map((shippingLines: ShippingLineModel[]) =>
        this.filterShippingLines
          ? shippingLines.filter((line) => `${line.id} ${line.name}`.toLowerCase().includes(this.filterShippingLines.toLowerCase()))
          : shippingLines
      ),
      untilDestroyed(this)
    );
  }

  onSearchValueChanged(query: string): void {
    this.searchControl.patchValue(query);
  }

  onSelect(shippingLine: ShippingLineModel): void {
    this.selectedName = shippingLine?.name;
    this.value = shippingLine?.id;
    this.onChange(shippingLine?.id);
    this.changed.emit(shippingLine?.id);
  }

  async writeValue(value: string): Promise<void> {
    super.writeValue(value);

    if (!value) {
      return;
    }
    const shippingLine = await this.shippingLineService
      .listShippingLines(value)
      .toPromise()
      .then((filteredValues) => filteredValues.find((shippingLine) => shippingLine.id === value));

    // In case no entry found with that id
    if (!shippingLine) {
      return;
    }

    this.value = shippingLine.id;
    this.selectedName = shippingLine.name;
  }
}
