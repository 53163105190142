<div class="support-nav" [ngClass]="{ 'support-nav--expanded': isExpanded }" *transloco="let translate">
  <button class="support-nav__btn" egButton egStyle="none" (click)="toggleSidebar()">
    <div fxLayoutAlign="center center">
      <img src="/assets/icons/list.svg" class="svg-to-white" alt="menu" />
    </div>
  </button>
  <div class="menu" *transloco="let translate">
    @if(!isExpanded){
    <p-tieredMenu [model]="items">
      <ng-template #item let-item>
        <a [routerLink]="item.route || item.routerLink" class="menu__link">
          @if(item.icon) {
          <img
            [src]="item.icon"
            class="menu__icon svg-to-white"
            alt="icon"
            pTooltip="{{ translate(TRANSLOCO_PREFIX + item.label) }}"
            tooltipPosition="right"
            positionLeft="25"
          />
          }@else{
          <span class="menu__labels">{{ translate(TRANSLOCO_PREFIX + item.label) }}</span>
          }
        </a>
      </ng-template>
    </p-tieredMenu>
    }@else{
    <p-panelMenu [model]="items">
      <ng-template #item let-item>
        @if(item.route){ @if(item.icon) {
        <img [src]="item.icon" class="menu__icon svg-to-white" alt="icon" />
        }
        <a [routerLink]="item.route">
          <span>{{ translate(TRANSLOCO_PREFIX + item.label) }}</span>
        </a>
        }@else{ @if(item.routerLink){
        <a [ngClass]="{ 'menu__link__panel__has-icon': item.icon }" [routerLink]="item.routerLink" class="menu__link__panel">
          <span>{{ translate(TRANSLOCO_PREFIX + item.label) }}</span>
        </a>
        }@else{ @if(item.icon) {
        <img [src]="item.icon" class="menu__icon svg-to-white" alt="icon" />
        }
        <span class="menu__panel">
          <span>{{ translate(TRANSLOCO_PREFIX + item.label) }}</span>
          @if(item.items){
          <img
            class="menu__dropdown-icon svg-to-white"
            src="/assets/icons/dropdown_grey_open.svg"
            [ngClass]="{ default: !item.expanded, rotated: item.expanded }"
          />
          }
        </span>
        } }
      </ng-template>
    </p-panelMenu>
    }
  </div>
</div>
