import { Component, Input, OnInit } from '@angular/core';
import { TableColumn } from '@eg/ui';
import { ChangeEntryModel } from '@railmybox/api-booking';
import { getHistoryTabsTableColumns } from './history-tab.table';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'rmb-history-tab',
  templateUrl: './history-tab.component.html',
  styleUrl: './history-tab.component.scss',
  standalone: false,
})
export class HistoryTabComponent implements OnInit {
  @Input() historyItems: ChangeEntryModel[];
  @Input() prefix = 'support';

  tableColumns: TableColumn<ChangeEntryModel>[] = [];
  lang: string;

  constructor(private translocoService: TranslocoService) {}

  ngOnInit() {
    this.lang = this.translocoService.getActiveLang();
    this.tableColumns = getHistoryTabsTableColumns.apply(this);
  }
}
