import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormErrorModule } from '../form-error/form-error.module';
import { InputModule } from '../input/input.module';
import { DatePickerModule } from 'primeng/datepicker';
import { MonthComponent } from './month.component';

@NgModule({
  declarations: [MonthComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FormErrorModule, InputModule, DatePickerModule],
  exports: [MonthComponent],
})
export class MonthModule {}
