import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackagingSelectComponent } from './packaging-select.component';
import { TranslocoModule } from '@jsverse/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormErrorModule, SelectModule } from '@eg/ui';

@NgModule({
  declarations: [PackagingSelectComponent],
  imports: [CommonModule, SelectModule, TranslocoModule, FlexLayoutModule, FormErrorModule],
  exports: [PackagingSelectComponent],
})
export class PackageTypeModule {}
