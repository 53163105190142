import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ConfirmationComponent, ModalService } from '@eg/ui';
import { RailMyBoxRoles } from '../util';
import { AuthQuery } from '../store/auth';
import { map } from 'rxjs';

export const routeGuard: CanActivateFn = (route, state) => {
  const modal = inject(ModalService);
  const authQuery = inject(AuthQuery);
  const allowedRoles = route.data['roles'] as string[];
  const userRoles = authQuery.getUserRoles();
  const admin = userRoles.find((role) => role === RailMyBoxRoles.ADMIN);

  if (admin) {
    return true;
  } else {
    return authQuery.hasAtLeastOnePermission(allowedRoles).pipe(
      map((isAllowed) => {
        if (isAllowed) {
          return true;
        } else {
          modal.open({
            title: '',
            showHeader: false,
            width: '370px',
            content: ConfirmationComponent,
            data: { type: 'cancel', source: 'accessDenied' },
            backdropClose: false,
          });
          return false;
        }
      })
    );
  }
};
