@if(type === 'capacity') {
<eg-pill
  [value]="train?.remainingCapacity"
  [colors]="capacityValueColors"
  type="number"
  [comparisonValue]="getPillComparisonValue(train?.remainingCapacity, train?.capacity)"
>
  <span class="value">{{ train.capacity - train.remainingCapacity }}</span> / {{ train.capacity }} TEU
</eg-pill>
} @else {
<eg-pill
  [value]="train?.remainingWeight"
  [colors]="capacityValueColors"
  type="number"
  [comparisonValue]="getPillComparisonValue(train?.remainingWeight, train?.weight)"
>
  <span class="value">{{ (train?.weight - train?.remainingWeight) / 1000.0 | egNumber }}</span> / {{ train?.weight }} t
</eg-pill>
}
