import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  // eslint-disable-next-line
  selector: 'input[type=number]',
  standalone: false,
})
export class NumberInputDirective implements OnInit {
  private _max = 100;
  private _min = 0;
  private readonly numberOnlyRegex: RegExp = /^\d*$/;

  @Input() set min(min: string | number) {
    this._min = Number(min);
    this.renderer.setAttribute(this.elementRef.nativeElement, 'min', min as string);
  }

  @Input() set max(max: string | number) {
    this._max = Number(max);
    this.renderer.setAttribute(this.elementRef.nativeElement, 'max', max as string);
  }

  @Output() numberChanged: EventEmitter<number> = new EventEmitter<number>();

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  /**
   * Sets the min value to 0.
   *
   * @returns {void}
   */
  ngOnInit(): void {
    if (!this.elementRef.nativeElement.getAttribute('min')) {
      this.min = 0;
    }
    this.renderer.setAttribute(this.elementRef.nativeElement, 'step', 'any');
  }

  /**
   * Verifies if the event contains number string or not
   */
  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent): void {
    if (!this.numberOnlyRegex.test(event.key) && event.key !== '.') {
      event.preventDefault();
    }
  }

  @HostListener('keyup', ['$event.target.value']) onChange(value: number): void {
    value = Number(value);
    if (value > this._max) {
      this.numberChanged.emit(this._max);
      this.elementRef.nativeElement.value = this._max;

      return;
    }

    this.numberChanged.emit(value);
  }

  /**
   * Verifies if the paste event only pastes numbers
   */
  @HostListener('paste', ['$event']) onPaste(event: Event): void {
    if (!this.numberOnlyRegex.test((event as ClipboardEvent).clipboardData.getData('text/plain'))) {
      event.preventDefault();
    }
  }
}
