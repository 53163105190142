import { Component, EventEmitter, Input, Output } from '@angular/core';

export type PillColor =
  | 'grey'
  | 'lightOrange'
  | 'orange'
  | 'lightGreen'
  | 'green'
  | 'blue'
  | 'lightRed'
  | 'red'
  | 'selected'
  | 'inactive'
  | 'light-green'
  | 'light-orange'
  | 'light-red';

export type PillColorType = {
  [key: string]: PillColor;
};

@Component({
  selector: 'eg-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
  standalone: false,
})
export class PillComponent {
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();
  @Input() value: any;
  @Input() translationPrefix = '';
  @Input() small: boolean;
  @Input() type: 'number' | 'enum' | 'object' = 'enum';

  @Input() colors: PillColorType = undefined;
  @Input() comparisonValue: any;

  onSelect() {
    this.selected.emit(this.value);
  }
}
