import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Country } from '../phone-number.interface';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { SelectItem } from '@eg/ui';

@Component({
  selector: 'rmb-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(CountrySelectComponent)],
  standalone: false,
})
export class CountrySelectComponent extends AbstractValueAccessorComponent<string> implements OnInit {
  private _countryList: Country[] = [];

  @Input() set countryList(countries: Country[]) {
    if (!countries) {
      return;
    }
    this._countryList = countries;

    this.typeahead$.next('');
  }

  @Input() placeholderMessage: string;
  @Input() readonly = false;
  items$: Observable<SelectItem[]>;
  typeahead$ = new Subject<string | null>();

  ngOnInit() {
    this.items$ = this.typeahead$.pipe(
      map((term) => {
        return this.searchCountry(term).map((country) => ({
          label: country.translatedName,
          value: country.alpha2Code,
          code: country.callingCodes,
        }));
      })
    );
  }

  private searchCountry(term: string | null): Country[] {
    if (term) {
      return this._countryList.filter((country) => {
        term = term.toLowerCase();
        const callingCode = '+' + country.callingCodes[0];

        return (
          country.translatedName.indexOf(term) > -1 || callingCode.indexOf(term) > -1 || country.alpha2Code.toLowerCase().indexOf(term) > -1
        );
      });
    }

    return this._countryList;
  }

  onValueSelect(value: string): void {
    this.onChange(value);
    this.changed.emit(value);
  }

  onBlur(): void {
    this.onTouched();
    this.blurred.emit();
  }
}
