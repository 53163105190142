import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';

@Component({
  selector: 'eg-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(CheckboxComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CheckboxComponent extends AbstractValueAccessorComponent<boolean> {
  inputChanged(): void {
    this.onChange(this.value);
    this.changed.emit(this.value);
    this.getErrors();
  }
}
