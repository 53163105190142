<ng-container *transloco="let translate">
  <div fxFlex="15" fxLayout="column">
    <div class="heading" fxFlex="100">{{ offer.shipmentStationAddress.name }}</div>
    <div class="date-time" fxFlex="100">
      <span class="date">
        {{ offer.shipmentStationAddress.date | egDate }}
      </span>
      <span class="time">
        {{ offer.shipmentStationAddress.date | egDate: 'time' }}
      </span>
    </div>
  </div>

  <div fxFlex="7" fxLayout="column">
    <img class="destination-separator" src="/assets/icons/small_next.svg" alt="NEXT" />
  </div>

  <div fxFlex="23" fxLayout="column">
    <div class="heading" fxFlex="100">
      {{ offer.destinationStationAddress.name }}
    </div>
    <div class="date-time" fxFlex="100">
      <span class="date">
        {{ offer.destinationStationAddress.date | egDate }}
      </span>
      <span class="time">
        {{ offer.destinationStationAddress.date | egDate: 'time' }}
      </span>
    </div>
  </div>

  <div [fxFlex]="offer.special ? 12 : 14" fxLayout="column">
    <div class="heading" fxFlex="100">{{ deliveryDateText }}</div>
    @if (offer.category === 'IMPORT') {
    <div class="date-time" fxFlex="100">
      <span class="date">
        {{ offer.earliestDelivery | egDate }}
      </span>
      <span class="time">
        {{ offer.earliestDelivery | egDate: 'time' }}
      </span>
    </div>
    } @if (offer.category !== 'IMPORT') {
    <div class="date-time" fxFlex="100">
      <span class="date">
        {{ offer.cutoff | egDate }}
      </span>
      <span class="time">
        {{ offer.cutoff | egDate: 'time' }}
      </span>
    </div>
    }
  </div>
  @if (offer.special) {
  <div fxFlex="2">
    <img
      src="/assets/icons/star.svg"
      [ngClass]="{
        'svg-to-warning': offer.special === 'TRAIN',
        'svg-to-error': offer.special === 'DISCOUNT'
      }"
      alt="Special train"
      [egTooltip]="
        offer.special === 'TRAIN'
          ? translate('routeSelection.offers.specialTrain')
          : offer.special === 'DISCOUNT'
          ? translate('routeSelection.offers.specialDiscount')
          : null
      "
    />
  </div>
  }

  <div fxFlex="9" fxLayout="row" fxLayoutAlign="space-around center" class="capacity-indicator pointer">
    <img src="/assets/icons/offer_{{ offer.status.toLowerCase() }}.svg" alt="{{ offer.status }}" [egTooltip]="offer.status | titlecase" />
    @if (offer.eco) {
    <img src="/assets/icons/trip_eco.svg" alt="ECO" />
    } @if (!offer.truckingAvailable) {
    <img src="/assets/icons/disabled_truck.svg" class="no-trucking" alt="No trucking" />
    }
  </div>

  <div fxFlex="13" fxLayout="column" fxLayoutAlign="end start">
    @if (!(offer?.special === 'TRAIN' && offer?.startingPrice?.amount === 0 )) {
    <span class="subheading" fxFlex="100">{{ translate('from') }}</span>
    <span class="heading pr-20" fxFlex="100"
      >{{ offer.startingPrice.amount | egNumber: '0.2-2' }} {{ offer.startingPrice.currency | egCurrencySymbol }}</span
    >
    }
  </div>

  @if (showCo2) {
  <div fxFlex="8" fxLayout="column" fxLayoutAlign="center">
    <span class="subheading" fxFlex="100">{{ translate('routeSelection.offers.co2') }}</span>
    <span class="heading pr-20" fxFlex="100">{{ offer.co2Emission }} kg</span>
  </div>
  } @if (!bookingButtonHidden) {
  <div [egTooltip]="offer?.status === 'BOOKED' ? translate('routeSelection.offers.noCapacity') : null" fxFlex="5" fxLayout="column">
    <button
      [disabled]="disableBooking"
      class="book-button mr-16 book-button__{{ offer?.status }}"
      (click)="bookingButtonClicked.emit(offer)"
    >
      <span>{{ bookingButtonText }}</span>
    </button>
  </div>
  }
</ng-container>
