import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { UploadDocManagementComponent } from './upload-doc-management.component';
import { TranslocoModule } from '@jsverse/transloco';
import { UploadDocManagementService } from './service/upload-doc-management.service';
import { SpinnerModule } from '@eg/ui';

@NgModule({
  declarations: [UploadDocManagementComponent],
  imports: [CommonModule, FlexLayoutModule, TranslocoModule, SpinnerModule],
  exports: [UploadDocManagementComponent],
  providers: [UploadDocManagementService],
})
export class UploadDocManagementModule {}
