import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressModule } from './address';
import { BillingAccountInfoModule } from './billing-account-info';
import { ContainerTerminalModule } from './container-terminal';
import { ContainerTypeModule } from './container-type';
import { CpaFacilityModule } from './cpa-name';
import { CustomerModule } from './customer';
import { CustomerGroupModule } from './customer-group';
import { CustomsOfficeModule } from './customs-office';
import { DepotModule } from './depot';
import { OperatorModule } from './operator';
import { OriginPortModule } from './port';
import { PackageTypeModule } from './packaging';
import { PhoneNumberModule } from './phone-number';
import { ProblemCardModule } from './problem-card';
import { SharedUtilModule } from '@railmybox/shared/util';
import { ShippingLineModule } from './shipping-line';
import { TechnicalNameModule } from './technical-name';
import { TemplateCardModule } from './template-card';
import { TrainVisitModule } from './trainvisit';
import { UiModule } from '@eg/ui';
import { VatModule } from './vat';
import { VeterinaryStationModule } from './veterinary-station';
import { TableNotFoundModule } from './table-not-found';
import { OfferCardModule } from './offer-card';
import { BookingServicesModule } from './booking-services';
import { StationSelectModule } from './station-select';
import { CustomerListModule } from './customer-list/customer-list.module';
import { ServiceSelectionModule } from './service-selection';
import { PageHeaderModule } from './page-header/page-header.module';
import { UploadDocManagementModule } from './upload-doc-management';
import { BookingConfirmationModule } from './booking-confirmation';
import { EditBookingConfirmationModule } from './edit-booking-confirmation';
import { DispoOrderChainModule } from './dispo-order-chain';
import { TrainOptionModule } from './trainoption';
import { WishlistCardModule } from './wishlist-card';
import { SubNavigationModule } from './sub-navigation';
import { EmptyPageModule } from './empty-page';
import { CostsTypeSelectModule } from './costs-type-select';
import { SecurityQuestionModule } from './security-question';
import { CreditorSelectModule } from './creditor-select';
import { HistoryTabModule } from './history-tab';

@NgModule({
  imports: [CommonModule, UiModule, SharedUtilModule],
  exports: [
    AddressModule,
    BookingServicesModule,
    BookingConfirmationModule,
    BillingAccountInfoModule,
    ContainerTerminalModule,
    ContainerTerminalModule,
    ContainerTypeModule,
    CostsTypeSelectModule,
    CpaFacilityModule,
    CreditorSelectModule,
    CustomerListModule,
    CustomerModule,
    CustomerGroupModule,
    CustomsOfficeModule,
    DepotModule,
    EmptyPageModule,
    HistoryTabModule,
    OfferCardModule,
    OperatorModule,
    OriginPortModule,
    PackageTypeModule,
    PageHeaderModule,
    PhoneNumberModule,
    ProblemCardModule,
    SecurityQuestionModule,
    ServiceSelectionModule,
    ShippingLineModule,
    StationSelectModule,
    SubNavigationModule,
    TableNotFoundModule,
    TechnicalNameModule,
    TemplateCardModule,
    TrainVisitModule,
    TrainOptionModule,
    VatModule,
    VeterinaryStationModule,
    UploadDocManagementModule,
    EditBookingConfirmationModule,
    DispoOrderChainModule,
    WishlistCardModule,
  ],
})
export class RailmyboxUiModule {}
