import { Component } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';

@Component({
  selector: 'eg-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(FileUploadComponent)],
  standalone: false,
})
export class FileUploadComponent extends AbstractValueAccessorComponent<string> {}
