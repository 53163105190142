import { Component, Input, ViewChild } from '@angular/core';
import { AbstractDateComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import dayjs from 'dayjs/esm';
import utc from 'dayjs/esm/plugin/utc';
import timezone from 'dayjs/esm/plugin/timezone';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DatePicker } from 'primeng/datepicker';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface DateValue {
  endDate: dayjs.Dayjs;
  startDate: dayjs.Dayjs;
}
export enum OutputType {
  datetime = 'datetime',
  date = 'date',
}

@UntilDestroy()
@Component({
  selector: 'eg-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(DateComponent)],
  standalone: false,
})
export class DateComponent extends AbstractDateComponent<any> {
  date: Date;

  @ViewChild('datePicker') datePicker: DatePicker;
  @Input() output: OutputType = OutputType.datetime;

  get formattedValue(): string {
    if (!this.value?.startDate) {
      return undefined;
    }

    return (this.value as DateValue).startDate.isValid()
      ? `${this.value.startDate.tz(dayjs.tz.guess(), true).format(this.localeFormat)}`
      : '';
  }

  /**
   * emits selected interval
   * @param value
   */
  onInputChanged(): void {
    const values = {
      startDate: this.date ? dayjs(this.date.toISOString()) : undefined,
      endDate: undefined,
    };

    if (!values?.startDate) {
      return;
    }

    this.value = values;
    this.onChange(this.getFormattedValue(values));
    this.changed.emit(this.getFormattedValue(values));
    this.onTouched();
  }

  openDatePicker(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.control.disabled || this.disabled) {
      return;
    }
    this.datePicker.toggle();
  }

  override writeValue(value: any): void {
    if (!value) {
      super.writeValue(undefined);
      return;
    }

    const date = dayjs(value);
    super.writeValue({
      startDate: date.isValid() ? date : undefined,
      endDate: date.isValid() ? date : undefined,
    });
    this.date = new Date(value);
  }

  override setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    if (isDisabled === this.control.disabled) {
      return;
    }

    const disableFn = isDisabled ? 'disable' : 'enable';
    this.control[disableFn]();
  }

  getFormattedValue(value: DateValue): string {
    return this.output === OutputType.datetime
      ? value.startDate.tz(dayjs.tz.guess(), true).toISOString()
      : value.startDate.tz(dayjs.tz.guess(), true).format('YYYY-MM-DD');
  }
}
