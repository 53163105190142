import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainoptionSelectComponent } from './trainoption-select.component';
import { TranslocoModule } from '@jsverse/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormErrorModule, PillModule, SelectModule } from '@eg/ui';
import { SharedPipesModule } from '@railmybox/shared/pipes';

@NgModule({
  declarations: [TrainoptionSelectComponent],
  imports: [CommonModule, SelectModule, TranslocoModule, FlexLayoutModule, FormErrorModule, PillModule, SharedPipesModule],
  exports: [TrainoptionSelectComponent],
})
export class TrainOptionModule {}
