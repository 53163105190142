import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'rmb-booking-services',
  templateUrl: './booking-services.component.html',
  styleUrls: ['./booking-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BookingServicesComponent {
  @Input() services: string[] = [];
}
