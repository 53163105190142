import { Component, Input } from '@angular/core';
import { PillColorType } from '@eg/ui';
import { TrainVisitModel } from '@railmybox/api-dispo';
import { comparisonColors } from '../color-pills-consts';

@Component({
  selector: 'rmb-capacity-pill',
  templateUrl: './capacity-pill.component.html',
  styleUrl: './capacity-pill.component.scss',
  standalone: false,
})
export class CapacityPillComponent {
  @Input() train: TrainVisitModel;
  @Input() type: 'capacity' | 'weight';
  capacityValueColors: PillColorType = comparisonColors;

  getPillComparisonValue(value1?: number, value2?: number): string {
    if (value2 - value1 < value2) {
      return 'LESS_THAN';
    } else if (value2 - value1 === value2) {
      return 'EQUAL';
    } else if (value2 - value1 > value2) {
      return 'GREATER_THAN';
    }
    return '';
  }
}
