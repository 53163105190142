export declare type ConfirmationSource =
  | 'waypoint'
  | 'address'
  | 'container'
  | 'booking'
  | 'template'
  | 'editBooking'
  | 'versionUpdate'
  | 'train-visits'
  | 'accessDenied';

export declare type ConfirmationType = 'delete' | 'cancel' | 'warning' | 'edit' | 'update' | 'yes-no';
