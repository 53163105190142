<div class="card" fxFlex="100" fxLayout="column" fxLayoutAlign="center center" *transloco="let translate">
  <div class="card__headline">
    {{ translate(prefix + '.confirmation.' + source + '.title') }}
  </div>
  <div class="card__text">
    {{
      translate(prefix + '.confirmation.' + source + '.text', {
        value: data,
        stationCopyFrom: stationCopyFrom,
        stationCopyTo: stationCopyTo,
        zoneFrom: zoneFrom,
      })
    }}
  </div>

  <div fxFlex="100" fxLayout="row" fxLayoutGap="20px">
    <button egButton class="card__btn_secondary" (click)="onResponseClick('yes')">
      {{ translate(prefix + '.confirmation.action.yes') }}
    </button>
    <button egButton class="card__btn_secondary" (click)="onResponseClick('no')">
      {{ translate(prefix + '.confirmation.action.no') }}
    </button>
  </div>
</div>
