import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectItem } from '../../../select';

@Component({
  selector: 'eg-pagination',
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss',
  standalone: false,
})
export class PaginationComponent {
  @Input() offset = 0;
  @Input() limit = 50;
  @Input() totalItems: number;
  limitOptions: SelectItem[] = [
    { label: '50', value: 50 },
    { label: '75', value: 75 },
    { label: '100', value: 100 },
    { label: '200', value: 200 },
  ];
  @Output() prevClick = new EventEmitter<void>();
  @Output() nextClick = new EventEmitter<void>();
  @Output() limitChange = new EventEmitter<number>();

  get hasNextPage(): boolean {
    return this.totalItems === this.limit;
  }

  onPreviousClick() {
    this.prevClick.emit();
  }

  onNextClick() {
    this.nextClick.emit();
  }

  changeLimit($event: number) {
    this.limitChange.emit($event);
  }
}
