import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@ngbracket/ngx-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UiModule } from '@eg/ui';
import { AddressModule, PhoneNumberModule } from '@railmybox/ui';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { ResetPasswordComponent } from './containers/reset-password/reset-password.component';
import { LoginComponent } from './containers/login/login.component';
import { SignUpComponent } from './containers/sign-up/sign-up.component';
import { EmailVerificationModule } from './containers/email-verification/email-verification.module';
import { EmailVerificationComponent } from './containers/email-verification/email-verification.component';

export const SignUpRoute = 'sign-up'; ///'+Md5.hashStr(environment.signUpKey);
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      { path: '', pathMatch: 'full', redirectTo: 'login' },
      { path: 'login', pathMatch: 'full', component: LoginComponent },
      { path: 'reset-password', pathMatch: 'full', component: ResetPasswordComponent },
      { path: SignUpRoute, pathMatch: 'full', component: SignUpComponent },
      { path: 'sign-up', pathMatch: 'full', redirectTo: 'login' },
      { path: 'verify', pathMatch: 'full', component: EmailVerificationComponent },
    ]),
    ReactiveFormsModule,
    UiModule,
    FlexModule,
    TranslocoModule,
    AddressModule,
    PhoneNumberModule,
    EmailVerificationModule,
  ],
  declarations: [ResetPasswordComponent, LoginComponent, SignUpComponent],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: [{ scope: 'auth', alias: 'auth' }],
    },
  ],
})
export class AuthModule {}
