import { Component, Input, OnInit } from '@angular/core';
import { AlertComponent } from './alert.component';
import { ProblemModel } from '@railmybox/api-booking';

@Component({
  selector: 'eg-problem-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: false,
})
export class ProblemAlertComponent extends AlertComponent implements OnInit {
  @Input() problem: ProblemModel;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.message = `<h3>${this.problem.title} ${this.problem.status}</h3>
        <p>${this.problem.detail}</p>`;
  }
}
