import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { ButtonSize, ButtonStyle, ButtonType } from '../button-types';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: 'button[egButton], a[egButton]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  exportAs: 'egButton',
  encapsulation: ViewEncapsulation.None,
})
/* eslint-enable */
export class ButtonComponent {
  @Input() egType: ButtonType = 'round';
  @Input() egStyle: ButtonStyle = 'primary';
  @Input() egButtonSize: ButtonSize = 'default';
  @Input() disabled: boolean;
  @Input() tabIndex: number;
  @Input() loading: boolean;

  @HostBinding('class.eg-button') get egButton() {
    return true;
  }

  @HostBinding('class.eg-button__default') get buttonSizeDefault() {
    return this.egButtonSize === 'default';
  }

  @HostBinding('class.eg-button__small') get buttonSizeSmall() {
    return this.egButtonSize === 'small';
  }

  @HostBinding('class.eg-button__large') get buttonSizeLarge() {
    return this.egButtonSize === 'large';
  }

  @HostBinding('class.eg-button__primary') get buttonStylePrimary() {
    return this.egStyle === 'primary';
  }

  @HostBinding('class.eg-button__danger') get buttonStyleDanger() {
    return this.egStyle === 'danger';
  }

  @HostBinding('class.eg-button__secondary') get buttonStyleSecondary() {
    return this.egStyle === 'secondary';
  }

  @HostBinding('class.eg-button__round') get buttonTypeRound() {
    return this.egType === 'round';
  }

  @HostBinding('class.eg-button__flat') get buttonTypeFlat() {
    return this.egType === 'flat';
  }

  @HostBinding('class.eg-button__next') get buttonTypeNext() {
    return this.egType === 'next';
  }

  @HostBinding('class.eg-button__prev') get buttonTypePrev() {
    return this.egType === 'prev';
  }

  @HostBinding('attr.tabindex') get attrTabindex() {
    return this.disabled ? -1 : this.tabIndex === null ? null : this.tabIndex;
  }

  @HostBinding('attr.disabled') get attrDisabled() {
    return this.disabled || null;
  }
}
