import { EventEmitter, Component, Output, Input, ViewEncapsulation } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';

@Component({
  selector: 'eg-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(ToggleComponent)],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: false,
})
export class ToggleComponent extends AbstractValueAccessorComponent<string | number> {
  @Input() isChecked = true;
  @Output() changes: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onChanged(event: Event) {
    this.changes.emit((event.target as HTMLInputElement).checked);
  }
}
