import { TemplateRef } from '@angular/core';
import { DateFormatType, SortDirection } from '@railmybox/shared/util';

export type ColumnType = 'text' | 'dynamic-text' | 'template' | 'number' | 'date' | 'boolean';

export type TableSourceType = 'dispo' | 'container-list' | 'goods-list';

export class TableColumn<T> {
  title: string;
  key: keyof T;
  translateColumnTitle?: boolean;
  columnType: ColumnType = 'text';
  flex = 10;
  class?: string;
  cellClass?: string;
  dataClass?: string;
  textSuffix?: string;
  truncate?: boolean;
  icon?: any;
  valueGetterFn?: (data: T) => string;
  template?: TemplateRef<{ entity: T; key?: string }>;
  sortable?: boolean;
  sortDirection?: SortDirection;
  sortKey?: string;

  /**
   * Same format from angular decimal pipe
   * in the following format:<br>
   * <code>{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}</code>.
   *   - `minIntegerDigits`: The minimum number of integer digits before the decimal point.
   * Default is `1`.
   *   - `minFractionDigits`: The minimum number of digits after the decimal point.
   * Default is `0`.
   *   - `maxFractionDigits`: The maximum number of digits after the decimal point.
   * Default is `3`.
   */
  numberFormat?: string;
  dateFormat?: DateFormatType;

  constructor(column: Partial<TableColumn<T>>) {
    Object.assign(this, {
      ...column,
    });
  }
}

export function getTableColumns<T>(columns: Partial<TableColumn<T>>[]): TableColumn<T>[] {
  return columns.map((partialColumn) => new TableColumn<T>(partialColumn));
}

export interface TableBaseData {
  id: string | number;
}

export interface SortDirectionChangeEvent<T = any> {
  key: string | number | symbol;
  sortDirection: SortDirection;
  valueGetterFn?: (data: T) => string;
}
