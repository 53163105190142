import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateRangeComponent } from './date-range.component';
import { FormErrorModule } from '../form-error/form-error.module';
import { InputModule } from '../input/input.module';
import { DatePickerModule } from 'primeng/datepicker';

@NgModule({
  declarations: [DateRangeComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FormErrorModule, ReactiveFormsModule, InputModule, DatePickerModule],
  exports: [DateRangeComponent],
})
export class DateRangeModule {}
