import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { FlexLayoutModule, FlexModule } from '@ngbracket/ngx-layout';
import { TranslocoModule } from '@jsverse/transloco';
import { CardTableComponent } from './components/card-table/card-table.component';
import { FormsModule } from '@angular/forms';
import { ActionMenuComponent } from './components/action/action-menu.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SharedPipesModule } from '@railmybox/shared/pipes';
import { SharedDirectiveModule } from '@railmybox/shared/directive';
import { AlertModule } from '../alert/alert.module';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ButtonModule } from '../button';
import { SelectModule } from '../select';
import { TooltipModule } from '../tooltip';
@NgModule({
  declarations: [CardTableComponent, ActionMenuComponent, PaginationComponent],
  imports: [
    CommonModule,
    CdkTableModule,
    FlexModule,
    TranslocoModule,
    FormsModule,
    AngularSvgIconModule.forRoot(),
    SharedDirectiveModule,
    SharedPipesModule,
    AlertModule,
    CommonModule,
    TranslocoModule,
    FlexModule,
    FlexLayoutModule,
    ButtonModule,
    SelectModule,
    TooltipModule,
  ],
  exports: [CardTableComponent, ActionMenuComponent, PaginationComponent],
})
export class TableModule {}
