import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleAutocompleteDirective } from './directive/google-autocomplete.directive';
import { VicinityComponent } from './vicinity/vicinity.component';
import { PlaceService } from './service/place.service';
import { LocationSearchComponent } from './location-search/location-search.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslocoModule } from '@jsverse/transloco';
import { SharedDirectiveModule } from '@railmybox/shared/directive';
import { InputModule, SelectModule } from '@eg/ui';

@NgModule({
  declarations: [GoogleAutocompleteDirective, VicinityComponent, LocationSearchComponent],
  imports: [CommonModule, SelectModule, FlexLayoutModule, InputModule, TranslocoModule, SharedDirectiveModule],
  exports: [GoogleAutocompleteDirective, VicinityComponent, LocationSearchComponent],
  providers: [PlaceService],
})
export class AddressModule {}
