import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormErrorModule } from '../form-error/form-error.module';
import { InputModule } from '../input/input.module';
import { DateTimeComponent } from './date-time.component';
import { DatePickerModule } from 'primeng/datepicker';

@NgModule({
  declarations: [DateTimeComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FormErrorModule, ReactiveFormsModule, InputModule, DatePickerModule],
  exports: [DateTimeComponent],
})
export class DateTimeModule {}
