import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CurrencyModel, MonetaryValueTypeModel } from '@railmybox/api-pricing';
import { AbstractValueAccessorComponent, FieldType, ValueAccessorUtil } from '@railmybox/shared/util';
import { Observable, tap } from 'rxjs';
import { ValueCurrencyService } from './service/valueCurrency.service';
import { valueCurrencyValidator } from './validator/value-currency.validator';

@UntilDestroy()
@Component({
  selector: 'rmb-value-currency',
  templateUrl: './value-currency.component.html',
  styleUrl: './value-currency.component.scss',
  providers: [...ValueAccessorUtil.getValueAccessorProvider(ValueCurrencyComponent)],
  standalone: false,
})
export class ValueCurrencyComponent extends AbstractValueAccessorComponent<MonetaryValueTypeModel> implements OnInit, OnChanges {
  @Input() defaultCurrency = 'EUR';
  @Input() currencyList$: Observable<CurrencyModel[]>;
  @Input() set formRules(rule: 'M' | 'O' | 'R') {
    this.formRule = rule;
    switch (rule) {
      case 'O':
        this.setFormValidators(null);
        break;
      case 'M':
        this.setFormValidators(Validators.required);
        break;
      case 'R':
        this.valueCurrencyForm.disable();
    }
  }

  valueCurrencyForm: FormGroup;
  formRule: FieldType | 'R';
  tooltip: string;

  constructor(
    private formBuilder: FormBuilder,
    private valueCurrencyService: ValueCurrencyService,
    changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
    this.valueCurrencyForm = this.getCurrencyForm();
  }

  async ngOnInit(): Promise<void> {
    this.currencyList$ = this.valueCurrencyService.getCurrencyList();
    this.valueCurrencyForm
      .get('currency')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.valueCurrencyForm.get('amount').updateValueAndValidity(), this.getTooltip();
        },
      });
    this.onCurrencyChange();
    this.setFormValidators(null);
  }

  writeValue(value: MonetaryValueTypeModel) {
    super.writeValue(value);
    if (value) {
      this.valueCurrencyForm.patchValue(value);
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    super.ngOnChanges(simpleChanges);
    if (this.defaultCurrency) {
      this.valueCurrencyForm.patchValue({ currency: this.defaultCurrency });
    }
  }

  onCurrencyChange(): void {
    this.valueCurrencyForm.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (currencyValue) => {
        if (this.valueCurrencyForm.invalid) {
          return;
        }
        this.onChange(currencyValue);
        this.changed.emit(currencyValue);
      },
    });
  }

  getTooltip() {
    this.currencyList$
      .pipe(
        tap((currencyArray) => {
          const matchedCurrency = currencyArray.find((item) => this.valueCurrencyForm.get('currency').value === item.alphaCode);
          if (matchedCurrency) {
            this.tooltip = matchedCurrency.name;
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  onBlur(): void {
    this.valueCurrencyForm.markAllAsTouched();
    this.valueCurrencyForm.updateValueAndValidity();
    this.onTouched();
  }

  getCurrencyForm(): FormGroup {
    return this.formBuilder.group({
      amount: [''],
      currency: [this.defaultCurrency],
    });
  }

  private setFormValidators(validatorFn: ValidatorFn | null): void {
    const valueCurrencyValidators: ValidatorFn[] = [Validators.max(500000), Validators.min(1), valueCurrencyValidator()];

    if (validatorFn) {
      valueCurrencyValidators.unshift(validatorFn);
    }

    this.valueCurrencyForm.get('amount').setValidators(valueCurrencyValidators);
    this.valueCurrencyForm.get('currency').setValidators(validatorFn);
    this.valueCurrencyForm.updateValueAndValidity();
  }

  setDisabledState(isDisabled: boolean) {
    super.setDisabledState(isDisabled);
    const disableFn = isDisabled ? 'disable' : 'enable';
    this.valueCurrencyForm[disableFn]({ emitEvent: false });
  }
}
