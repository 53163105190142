import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: false,
})
export class TruncatePipe implements PipeTransform {
  /**
   *
   * @param value
   * @param limit
   * @param completeWords
   * @param ellipsis
   * @returns
   *
   * {{longString | truncate : limit : completeWords : ellipsis }}
   * {{longString | truncate : 50 : false : '---' }}
   * {{longString | truncate : 50 }}
   */
  transform(value: string, limit = 40, completeWords = false, ellipsis = '...') {
    if (completeWords) {
      limit = value.substring(0, limit).lastIndexOf(' ');
    }
    return value.length > limit ? value.substring(0, limit) + ellipsis : value;
  }
}
