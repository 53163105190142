import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CreditorInfoModel, CreditorService } from '@railmybox/api-user';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { Observable, catchError, map, of, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'rmb-creditor-select',
  templateUrl: './creditor-select.component.html',
  styleUrl: './creditor-select.component.scss',
  providers: [...ValueAccessorUtil.getValueAccessorProvider(CreditorSelectComponent)],
})
export class CreditorSelectComponent extends AbstractValueAccessorComponent<string> implements OnInit {
  creditors$: Observable<CreditorInfoModel[]>;
  searchControl: FormControl = new FormControl<string | null>(undefined);
  selectedName: string;
  @Input() clearable = true;

  constructor(
    private creditorService: CreditorService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.creditors$ = this.searchControl.valueChanges.pipe(
      switchMap((query: string) => (query ? this.creditorService.listCreditors(query) : of([]))),
      untilDestroyed(this)
    );
  }

  onSearchValueChanged(query: string): void {
    this.searchControl.patchValue(query);
  }

  onSelect(creditor: CreditorInfoModel): void {
    this.selectedName = creditor ? `${creditor?.name} (${creditor?.creditorId})` : null;
    this.value = creditor?.id;
    this.onChange(creditor?.id);
    this.changed.emit(creditor?.id);
  }

  writeValue(value: string): void {
    super.writeValue(value);

    if (value) {
      this.creditorService
        .listCreditors()
        .pipe(
          untilDestroyed(this),
          map((filteredValues) => filteredValues.find((creditor) => creditor.id === value)),
          switchMap((creditor) => {
            if (creditor) {
              this.value = creditor.id;
              this.selectedName = `${creditor.name} (${creditor.creditorId})`;
            }
            return of(null);
          }),
          catchError(() => {
            return of(null);
          })
        )
        .subscribe();
    } else {
      this.value = undefined;
      this.selectedName = undefined;
    }
  }
}
