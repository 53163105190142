import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@ngbracket/ngx-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { PhoneNumberComponent } from './phone-number.component';
import { CountryCodeService } from './service/country-code.service';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { InputModule, SelectModule } from '@eg/ui';
import { CountrySelectComponent } from './country-select/country-select.component';
import { SharedDirectiveModule } from '@railmybox/shared/directive';

@NgModule({
  declarations: [PhoneNumberComponent, CountrySelectComponent],
  imports: [CommonModule, FlexModule, ReactiveFormsModule, InputModule, SelectModule, SharedDirectiveModule, TranslocoModule],
  exports: [PhoneNumberComponent],
  providers: [CountryCodeService, { provide: TRANSLOCO_SCOPE, useValue: 'countryList' }],
})
export class PhoneNumberModule {}
