import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateCardComponent } from './template-card.component';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { InputModule } from '@eg/ui';

@NgModule({
  declarations: [TemplateCardComponent],
  imports: [CommonModule, ReactiveFormsModule, InputModule, TranslocoModule, FlexLayoutModule],
  exports: [TemplateCardComponent],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: [{ scope: 'dashboard' }],
    },
  ],
})
export class TemplateCardModule {}
