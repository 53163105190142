import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DropdownPosition, NgSelectComponent } from '@ng-select/ng-select';
import { SelectItem } from './select.interface';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { AddTagFn } from '@ng-select/ng-select/lib/ng-select.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'eg-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(SelectComponent)],
  standalone: false,
})
export class SelectComponent extends AbstractValueAccessorComponent<any> {
  classes = 'block relative';
  @ViewChild('ngSelect', { static: true }) ngSelect: NgSelectComponent;
  @Input() items: SelectItem[];
  @Input() dropDownPosition: DropdownPosition = 'auto';
  @Input() selectOnTab = true;
  @Input() clearable = true;
  @Input() editableSearchTerm = false;
  @Input() addTag: boolean | AddTagFn = false;
  @Input() bindValue: 'label' | 'value' = 'value';
  @Input() bindLabel = 'label';
  @Input() typeahead: Subject<string>;
  @Input() labelTemplate: TemplateRef<{ item: SelectItem }>;
  @Input() optionTemplate: TemplateRef<{ item: SelectItem }>;
  @Input() displayValue: number;

  /**
   * Emits the value to parent component
   * If it is being used as a formControl then update the formValue
   */
  onSelectionChanged(value: string | Record<string, string>): void {
    this.value = typeof value === 'object' ? value[this.bindValue] : value;
    this.onChange(this.value);
    this.changed.emit(this.value);
  }

  /**
   * Marks the control as touched
   */
  onSelectTouched(): void {
    if (this.onTouched instanceof Function) {
      this.onTouched();
    }

    this.blurred.emit();
  }

  /**
   * Opens the select box even if label is clicked
   */
  onLabelClick(): void {
    this.ngSelect.open();
  }

  compareFn(item, selected) {
    return item.value == selected;
  }
}
