import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@ngbracket/ngx-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { InputModule, SelectModule, TooltipModule } from '@eg/ui';
import { SharedDirectiveModule } from '@railmybox/shared/directive';
import { CurrencySelectComponent } from './currency-select/currency-select.component';
import { ValueCurrencyComponent } from './value-currency.component';
import { ValueCurrencyService } from './service/valueCurrency.service';

@NgModule({
  declarations: [ValueCurrencyComponent, CurrencySelectComponent],
  imports: [
    CommonModule,
    FlexModule,
    ReactiveFormsModule,
    InputModule,
    SelectModule,
    SharedDirectiveModule,
    TranslocoModule,
    TooltipModule,
  ],
  exports: [ValueCurrencyComponent],
  providers: [ValueCurrencyService],
})
export class ValueCurrencyModule {}
