import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractDateComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import utc from 'dayjs/esm/plugin/utc';
import timezone from 'dayjs/esm/plugin/timezone';
import dayjs from 'dayjs/esm';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Calendar } from 'primeng/calendar';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface DatePickerValue {
  endDate: dayjs.Dayjs;
  startDate: dayjs.Dayjs;
}

@UntilDestroy()
@Component({
  selector: 'eg-month-range',
  templateUrl: './month-range.component.html',
  styleUrl: './month-range.component.scss',
  providers: [...ValueAccessorUtil.getValueAccessorProvider(MonthRangeComponent)],
})
export class MonthRangeComponent
  extends AbstractDateComponent<{
    endDate: any;
    startDate: any;
  }>
  implements OnInit
{
  monthValue: Date[] = [];
  @ViewChild('rangeMonth') rangeMonth: Calendar;

  get formattedValue(): string {
    if (!this.value?.endDate || !this.value?.startDate) {
      return undefined;
    }
    const values = { startDate: this.value.startDate, endDate: this.value.endDate };
    return `${dayjs(values.startDate).format(this.monthRange)} - ${dayjs(values.endDate).format(this.monthRange)}`;
  }

  onSelect(_value: string): void {
    const values = {
      startDate: this.monthValue[0] ? dayjs(this.monthValue[0].toISOString()) : undefined,
      endDate: this.monthValue[1] ? dayjs(this.monthValue[1].toISOString()) : undefined,
    };

    if (!values?.endDate || !values?.startDate) {
      return;
    }
    this.value = values;
    this.onChange(this.getDayValues(values));
    this.changed.emit(this.getDayValues(values));
    this.onTouched();
  }

  openDatePicker(event: MouseEvent): void {
    if (this.control?.disabled || this.disabled) {
      return;
    }
    this.rangeMonth.toggle();
    event.stopPropagation();
    event.preventDefault();
  }

  override writeValue(value: { endDate: any; startDate: any }): void {
    if (value?.startDate && value?.endDate) {
      super.writeValue({
        endDate: value.endDate,
        startDate: value.startDate,
      });

      if (this.monthValue) {
        this.monthValue[0] = this.startMonthDisplay(this.value.startDate);
        this.monthValue[1] = new Date(this.value.endDate);
      }
    }
    if (value === null) {
      super.writeValue(value);
      this.rangeMonth.clear();
    }
  }

  override setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    if (isDisabled === this.control.disabled) {
      return;
    }
    const disableFn = isDisabled ? 'disable' : 'enable';
    this.control[disableFn]({ emitEvent: false });
  }

  getDayValues(value: DatePickerValue): any {
    const start = dayjs(value?.startDate).startOf('month').format('YYYY-MM-DD');
    const end = dayjs(value?.endDate).endOf('month').format('YYYY-MM-DD');
    return { startDate: start, endDate: end };
  }

  startMonthDisplay(date: Date) {
    const start = new Date(date);
    start.setMonth(start.getMonth() - 1);
    return start;
  }
}
