import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateComponent } from './date.component';
import { FormErrorModule } from '../form-error/form-error.module';
import { InputModule } from '../input/input.module';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [DateComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FormErrorModule, ReactiveFormsModule, InputModule, CalendarModule],
  exports: [DateComponent],
})
export class DateModule {}
