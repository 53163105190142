<eg-select
  [readonly]="readonly"
  [value]="value"
  [labelTemplate]="labelTemplate"
  [optionTemplate]="optionTemplate"
  [items]="(items$ | async) || []"
  [typeahead]="typeahead$"
  [disabled]="disabled"
  (changed)="onValueSelect($event)"
  (blurred)="onBlur()"
  [clearable]="false"
>
</eg-select>

<ng-template #labelTemplate let-item="item">
  {{ item.symbol ? '' + item.symbol : '' }}
</ng-template>

<ng-template #optionTemplate let-item="item">
  <div class="option-container">
    <span>{{ item.label }}</span>
    <span>{{ item.symbol }}</span>
  </div>
</ng-template>
