import { Component, Input } from '@angular/core';
import { ModalRef, ConfirmationType } from '@eg/ui';

@Component({
  selector: 'rmb-edit-booking-confirmation',
  templateUrl: './edit-booking-confirmation.component.html',
  styleUrls: ['./edit-booking-confirmation.component.scss'],
  standalone: false,
})
export class EditBookingConfirmationComponent {
  @Input() type: ConfirmationType;

  readonly TRANSLOCO_PREFIX = 'confirmation';
  source = 'editBooking';

  constructor(private modalRef: ModalRef) {}

  onCloseClick(): void {
    this.modalRef.close();
  }

  onResponseClick(): void {
    this.modalRef.close();
  }
}
