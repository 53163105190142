import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthQuery, AuthStoreService, CustomerQuery, RailMyBoxRoles } from '@railmybox/auth';
import { TranslocoService } from '@jsverse/transloco';
import { filter, map } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VersionCheckerService } from '../services/version-checker.service';
import { ConfirmationComponent, ModalRef, ModalService } from '@eg/ui';

enum NAV_STATE {
  login = 'LOGIN',
  user = 'USER',
  dashboard = 'DASHBOARD',
}

@UntilDestroy()
@Component({
  selector: 'rmb-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: false,
})
export class MainComponent implements OnInit, OnChanges {
  activeLang: string;
  navState: string;
  listShow = false;
  languageList = ['en', 'de'];
  isDashboardHidden: boolean;
  rmbAuth: boolean;
  showSupportNav = false;
  hasSupportPermission = false;
  hasPricingOrAccountingPermission: boolean;
  updateModal: ModalRef<any>;

  constructor(
    private transloco: TranslocoService,
    private authStoreService: AuthStoreService,
    private router: Router,
    private authQuery: AuthQuery,
    private customerQuery: CustomerQuery,
    private versionCheckerService: VersionCheckerService,
    private modalService: ModalService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.customerQuery
      .getRmbAuth()
      .pipe(untilDestroyed(this))
      .subscribe((auth) => (this.rmbAuth = auth));
  }

  async ngOnInit(): Promise<void> {
    this.getActiveLanguage();

    this.customerQuery
      .getRmbAuth()
      .pipe(untilDestroyed(this))
      .subscribe((auth) => (this.rmbAuth = auth));

    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        map((e) => {
          const splitUrl = e.urlAfterRedirects.split('/');
          this.isDashboardHidden = splitUrl.includes('dashboard') || splitUrl.includes('support') || splitUrl.includes('wishlist');
          this.showSupportNav = splitUrl.includes('support');
        })
      )
      .subscribe();

    this.authStoreService.getAuthQuery().loggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.navState = NAV_STATE.user;

        return;
      }
      this.navState = NAV_STATE.login;
    });

    const groups = [
      RailMyBoxRoles.SUPPORT,
      RailMyBoxRoles.ADMIN,
      RailMyBoxRoles.ACCOUNTING,
      RailMyBoxRoles.PRICING,
      RailMyBoxRoles.TYPIST,
      RailMyBoxRoles.OPERATOR,
    ];
    this.authQuery
      .hasAtLeastOnePermission(groups)
      .pipe(untilDestroyed(this))
      .subscribe((isAllowed) => {
        this.hasSupportPermission = isAllowed;
      });

    this.isPricingOrAccountingMember();

    this.versionCheckerService.isNewVersionAvailable$.pipe(untilDestroyed(this)).subscribe((updateAvailable) => {
      if (updateAvailable && !this.updateModal) {
        this.updateModal = this.modalService.open({
          title: '',
          showHeader: false,
          width: '370px',
          content: ConfirmationComponent,
          data: { data: null, type: 'update', source: 'versionUpdate' },
          backdropClose: true,
        });

        this.updateModal.afterClosed$.pipe(untilDestroyed(this)).subscribe((res) => {
          if (res.data === 'UPDATE') {
            this.versionCheckerService.applyUpdate();
            this.updateModal = undefined;
          }
        });
      }
    });
  }

  /**
   * Gets active language from local storage or transloco
   */
  getActiveLanguage() {
    this.authStoreService
      .getAuthQuery()
      .getUserLanguage()
      .subscribe((userLanguage) => {
        if (userLanguage) {
          this.transloco.setActiveLang(userLanguage);
          this.activeLang = userLanguage;

          return;
        }

        this.activeLang = this.transloco.getActiveLang();
        this.authStoreService.updateUIState({ userLanguage: this.activeLang });
      });
  }

  /**
   * Changes active language
   */
  setActiveLang(lang: string) {
    this.authStoreService.updateUIState({ userLanguage: lang });
    this.switchLanguageList();
  }

  /**
   * Navigation language list display switch
   */
  switchLanguageList() {
    this.listShow = !this.listShow;
  }

  /**
   * logout function
   */
  async logout(event: any) {
    event.preventDefault();
    event.stopPropagation();

    await this.authStoreService.logout({ global: true }).catch((err) => console.error(err));
  }

  isPricingOrAccountingMember() {
    const roles = [RailMyBoxRoles.ADMIN, RailMyBoxRoles.ACCOUNTING, RailMyBoxRoles.PRICING, RailMyBoxRoles.SUPPORT];
    this.authQuery
      .hasAtLeastOnePermission(roles)
      .pipe(untilDestroyed(this))
      .subscribe((isAllowed) => {
        this.hasPricingOrAccountingPermission = isAllowed;
      });
  }

  async onCreateBooking() {
    await this.router.navigate(['/booking', 'route']);
  }
}
