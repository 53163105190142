import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiselectComponent } from './multiselect.component';
import { FlexModule } from '@ngbracket/ngx-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@jsverse/transloco';
import { DropdownModule } from '../dropdown';
import { FormErrorModule } from '../form-error/form-error.module';
import { InputModule } from '../input/input.module';
import { TooltipModule } from '../tooltip';
import { SharedDirectiveModule } from '@railmybox/shared/directive';

@NgModule({
  declarations: [MultiselectComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    FormErrorModule,
    FlexModule,
    InputModule,
    TranslocoModule,
    DropdownModule,
    TooltipModule,
    SharedDirectiveModule,
  ],
  exports: [MultiselectComponent],
})
export class MultiselectModule {}
