import { ChangeDetectorRef, Component, Input, OnChanges, Optional } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';
import { SelectItem } from '@eg/ui';
import { InvoiceLanguageTypeModel, ServiceAdminModel } from '@railmybox/api-booking';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';

@Component({
  selector: 'rmb-service-selection',
  templateUrl: './service-selection.component.html',
  styleUrls: ['./service-selection.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(ServiceSelectionComponent)],
  standalone: false,
})
export class ServiceSelectionComponent extends AbstractValueAccessorComponent<string> implements OnChanges {
  searchControl: FormControl = new FormControl(undefined);
  @Input() services: ServiceAdminModel[];
  @Input() invoiceLang: InvoiceLanguageTypeModel;
  @Input() clearable = false;
  @Input() dropDownPosition = 'auto';
  service: SelectItem[];

  constructor(protected changeDetectorRef: ChangeDetectorRef, @Optional() _controlContainer: ControlContainer) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnChanges() {
    this.service = this.services?.map((el) => {
      return { label: this.invoiceLang === InvoiceLanguageTypeModel.De ? el.labelDe : el.labelEn, value: el.serviceId };
    });
  }

  onSelect(service: string): void {
    this.onChange(service);
    this.changed.emit(service);
  }

  onBlur(): void {
    this.onTouched();
    this.blurred.emit();
  }
}
