import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslocoModule } from '@jsverse/transloco';
import { SharedPipesModule } from '@railmybox/shared/pipes';
import { OfferCardComponent } from './offer-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule, TooltipModule } from '@eg/ui';

@NgModule({
  declarations: [OfferCardComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TooltipModule,
    SharedPipesModule,
    TranslocoModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [OfferCardComponent],
})
export class OfferCardModule {}
