<eg-input
  class="ngx-daterangepicker-action mb-0"
  [value]="formattedValue"
  [label]="labelWithSuffix"
  [name]="name"
  [placeholder]="placeholder"
  [readonly]="true"
  [disabled]="disabled"
  (click)="openDatePicker($event)"
  (blurred)="markInputAsTouched()"
>
  <img egFieldSuffixDirective src="/assets/icons/field_date.svg" class="date-icon ngx-daterangepicker-action" />
</eg-input>

<p-calendar
  #dateTimePicker
  class="p-calendar date-time"
  [(ngModel)]="dateTime"
  [defaultDate]="defaultDate"
  [inputId]="'dateTimeInput'"
  [minDate]="minDateFormatted"
  [maxDate]="maxDateFormatted"
  [readonlyInput]="true"
  [placeholder]="placeholder"
  [showTime]="true"
  [hourFormat]="'24'"
  [stepMinute]="timeInterval"
  (onSelect)="onInputChanged()"
  [hideOnDateTimeSelect]="false"
>
</p-calendar>

@if (control?.touched && control?.errors && errorMessage) {
<eg-form-error [errorMessage]="errorMessage" class="input-error"></eg-form-error>
}
