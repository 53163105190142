import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OfferResultModel } from '@railmybox/api-booking';

// eslint-disable-next-line @nx/enforce-module-boundaries

@Component({
  selector: 'rmb-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.scss'],
  standalone: false,
})
export class OfferCardComponent {
  @Input() offer: OfferResultModel;
  @Input() deliveryDateText = 'Delivery Date';
  @Input() bookingButtonText = 'Book now';
  @Input() bookingButtonHidden = false;
  @Input() disableBooking = false;
  @Input() showCo2 = false;
  @Output() bookingButtonClicked: EventEmitter<OfferResultModel> = new EventEmitter<OfferResultModel>();
}
