import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProblemCardComponent } from './problem-card.component';
import { TranslocoModule } from '@jsverse/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { SharedUtilModule } from '@railmybox/shared/util';
import { SharedPipesModule } from '@railmybox/shared/pipes';
import { FormErrorModule } from '@eg/ui';

@NgModule({
  declarations: [ProblemCardComponent],
  imports: [CommonModule, TranslocoModule, FlexLayoutModule, FormErrorModule, SharedUtilModule, SharedPipesModule],
  exports: [ProblemCardComponent],
})
export class ProblemCardModule {}
