import { Component, Input } from '@angular/core';
import { slideAnimation } from '@railmybox/shared/util';

@Component({
  selector: 'eg-expandable',
  templateUrl: './expandable.component.html',
  styleUrls: ['./expandable.component.scss'],
  animations: [slideAnimation],
  standalone: false,
})
export class ExpandableComponent {
  @Input() heading: string;
  @Input() expanded: boolean;

  toggleExpansion(): void {
    this.expanded = !this.expanded;
  }
}
