import { Component } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';

@Component({
  selector: 'eg-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(TimeComponent)],
})
export class TimeComponent extends AbstractValueAccessorComponent<string> {
  onTimeChange(value: string): void {
    if (value.length > 5) {
      return;
    }
    this.value = value;
    this.onChange(this.value);
    this.formControl?.setValue(this.value);
    this.changed.emit(this.value);
  }

  writeValue(value: string): void {
    if (value) {
      this.value = value;
      super.writeValue(value);
    } else {
      super.writeValue('00:00');
    }
  }
}
