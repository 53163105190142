import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpaFacilitySelectComponent } from './cpa-facility-select.component';
import { TranslocoModule } from '@jsverse/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormErrorModule, SelectModule } from '@eg/ui';

@NgModule({
  declarations: [CpaFacilitySelectComponent],
  imports: [CommonModule, SelectModule, TranslocoModule, FlexLayoutModule, FormErrorModule],
  exports: [CpaFacilitySelectComponent],
})
export class CpaFacilityModule {}
