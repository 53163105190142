import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabGroupComponent } from './tab-group.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslocoModule } from '@jsverse/transloco';
import { TabGroupService } from './service';

@NgModule({
  declarations: [TabGroupComponent],
  imports: [CommonModule, FlexLayoutModule, TranslocoModule],
  providers: [TabGroupService],
  exports: [TabGroupComponent],
})
export class TabGroupModule {}
