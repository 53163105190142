<div [style.width.px]="prefixContainerWidth" class="inline absolute eg-input__prefix">
  <ng-content class="inline-content" select="[egFieldPrefixDirective]"></ng-content>
</div>

@if (type !== 'number') {
<input
  class="eg-input__input"
  [class.no-label]="!labelWithSuffix"
  [style.paddingLeft.px]="prefixContainerWidth + prefixInputPadding"
  [(ngModel)]="value"
  [id]="id"
  [type]="type"
  [placeholder]="placeholder"
  [class.has-suffix]="hasSuffixIcon"
  [readonly]="readonly"
  [autocomplete]="autocomplete"
  [disabled]="disabled"
  (change)="inputChanged()"
  (keydown)="handleKeyDown($event)"
  (keyup)="handleKeyUp($event)"
  (blur)="markInputAsTouched()"
/>
} @else {
<input
  class="eg-input__input"
  type="text"
  [mask]="numberMask"
  [showMaskTyped]="false"
  [decimalMarker]="decimalMarker"
  [thousandSeparator]="inputSeparator"
  [leadZero]="true"
  [class.no-label]="!labelWithSuffix"
  [style.paddingLeft.px]="prefixContainerWidth + prefixInputPadding"
  [(ngModel)]="value"
  [id]="id"
  [placeholder]="placeholder"
  [class.has-suffix]="hasSuffixIcon"
  [readonly]="readonly"
  [autocomplete]="autocomplete"
  [disabled]="disabled"
  (change)="inputChanged()"
  (keyup)="onNumberKeyup($event)"
  (blur)="markInputAsTouched()"
/>
}

<label [for]="id" [style.left.px]="prefixContainerWidth + prefixInputPadding" class="absolute eg-input__label">
  {{ labelWithSuffix }}
</label>

<div class="inline absolute eg-input__suffix">
  <ng-content select="[egFieldSuffixDirective]"></ng-content>
</div>
@if (control?.touched && control?.errors && errorMessage) {
<eg-form-error [errorMessage]="errorMessage" class="eg-input__error"></eg-form-error>
}
