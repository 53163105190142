import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

interface Separator {
  decimalSeparator: '.' | ',';
  thousandSeparator: '.' | ',';
}

@UntilDestroy()
@Pipe({
  name: 'egNumber',
  pure: false,
  standalone: false,
})
export class EgNumberPipe implements PipeTransform {
  private separatorRecord: Record<string, Separator> = {
    en: {
      decimalSeparator: '.',
      thousandSeparator: ',',
    },
    de: {
      decimalSeparator: ',',
      thousandSeparator: '.',
    },
  };
  private subscription: Subscription;
  private lastValue: string | number;
  private lastFormattedValue: string;
  private decimalPipe = new DecimalPipe('en-US');

  constructor(private translocoService: TranslocoService, private changeDetectorRef: ChangeDetectorRef) {}

  transform(value: number | string, digitsInfo?: string): string {
    if (value === this.lastValue) {
      return this.lastFormattedValue;
    }

    this.lastValue = value;
    const activeLang = this.translocoService.getActiveLang();

    this.subscription?.unsubscribe();

    this.subscription = this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe((lang) => {
      this.lastFormattedValue = this.getFormattedString(lang, digitsInfo);
      this.changeDetectorRef.markForCheck();
    });

    this.lastFormattedValue = this.getFormattedString(activeLang, digitsInfo);

    return this.lastFormattedValue;
  }

  private getFormattedString(lang: string, digitsInfo?: string): string {
    const formattedString = this.decimalPipe.transform(this.lastValue, digitsInfo);

    if (!formattedString || lang === 'en') {
      return formattedString;
    }

    const { decimalSeparator, thousandSeparator } = this.separatorRecord[lang];

    const [integerPart, decimalPart] = formattedString.split('.');
    const integerPartWithThousandSeparator = integerPart.replace(/,/g, thousandSeparator);

    return decimalPart ? `${integerPartWithThousandSeparator}${decimalSeparator}${decimalPart}` : integerPartWithThousandSeparator;
  }
}
