import { AbstractControl, ValidatorFn } from '@angular/forms';
import { MonetaryValueTypeModel } from '@railmybox/api-pricing';

export function valueCurrencyValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valueCurrency: MonetaryValueTypeModel = control.value || '';
    if (valueCurrency) {
      return null;
    }

    return { invalid: true };
  };
}
