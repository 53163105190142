import { Injectable } from '@angular/core';
import { ModalService } from '@eg/ui';
import { BehaviorSubject, map } from 'rxjs';
import { UnsavedChangesWarningComponent } from '../unsaved-changes-warning.component';

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesService {
  private formDataSubject = new BehaviorSubject<any>({});
  formData$ = this.formDataSubject.asObservable();

  constructor(private modalService: ModalService) {}

  unsavedChanges() {
    const modalRef = this.modalService.open({
      title: '',
      showHeader: false,
      width: '400px',
      content: UnsavedChangesWarningComponent,
      data: { prefix: 'support', source: 'unsavedChanges' },
      backdropClose: false,
    });

    return modalRef.afterClosed$.pipe(map((result) => result.data === 'yes'));
  }
}
