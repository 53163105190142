<div class="pagination" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between space-between" *transloco="let translate">
  <div fxFlex fxLayoutAlign="flex-start">
    <button [disabled]="offset" egButton egButtonSize="small" egType="prev" (click)="onPreviousClick()">
      {{ translate('action.previous') }}
    </button>
  </div>
  <div fxFlex="15" fxLayoutAlign="center" fxLayoutGap="16px" fxLayoutAlign="flex-end">
    <div class="pagination__limit-select">
      <eg-select [items]="limitOptions" [value]="limit" (changed)="changeLimit($event)" [clearable]="false"></eg-select>
    </div>
    <button egButton egButtonSize="small" egType="next" (click)="onNextClick()" [disabled]="!hasNextPage">
      {{ translate('action.next') }}
    </button>
  </div>
</div>
