<div [ngClass]="{ disabledSelection: disabledSelection }" fxFlex="100" fxLayout="row wrap" class="pb-8 card-table">
  <!-- Table header start -->
  <div
    #tableHeader
    fxFlex="100"
    fxLayout="row wrap"
    class="table__header pt-12 pb-8"
    [ngClass]="{ 'freeze-table-header': fixedHeader && isScrolling }"
  >
    <div fxFlex="100" fxLayoutAlign="start center" fxLayoutGap="4px">
      @for (column of columns; track column; let idx = $index) {
      <div [fxFlex]="column.flex" class="table__header-cell" [ngClass]="column.class">
        @if (!column.translateColumnTitle) {
        <span class="header-text" [class.pointer]="column.sortable" (click)="sortData(idx)">
          {{ column.title }}
        </span>
        } @else {
        <span class="header-text" [class.pointer]="column.sortable" (click)="sortData(idx)">
          {{ column.title | transloco }}
        </span>
        } @if (column.sortable) { @if (column.sortDirection) {
        <img
          class="pt-3 pl-4 inline-block"
          src="/assets/icons/dropdown_{{ column.sortDirection === sortDirections.ASC ? 'open' : 'close' }}.svg"
          [alt]="column.sortDirection"
        />
        } }
      </div>
      }
    </div>
  </div>
  <!-- Table header end -->

  <!-- Table body start -->
  <div fxFlex="100" fxLayout="row wrap" class="table">
    @if (dataSource?.length !== 0) { @for (row of dataSource; track trackBy(idx, row); let idx = $index) {
    <div
      fxFlex="100"
      fxLayout="row wrap"
      class="table__row py-8 mb-10"
      [ngClass]="row[rowClassField] | lowercase"
      [ngClass]="{ 'multi-selected': isRowSelected(row) }"
      [class.table__row--selected]="row.id && selectedRowId === row.id"
    >
      <div fxFlex="100" fxLayoutAlign="start center" fxLayoutGap="4px" (click)="rowSelected(row)">
        @for (column of columns; track column) {
        <div class="table__data-cell" [ngClass]="column.cellClass" [fxFlex]="column.flex">
          <span [egTooltip]="column.tooltipGetterFn ? column.tooltipGetterFn(row) : null">
            @switch (column.columnType) {
            <!-- Type Column: [Template] Start -->
            @case ('template') {
            <ng-container *ngTemplateOutlet="column.template; context: { entity: row }"></ng-container>
            }
            <!-- Type Column: [Template] End -->
            <!-- Type Column: [Dynamic Text] Start -->
            @case ('dynamic-text') { @if (!column?.truncate) {
            <span [ngClass]="column.dataClass" class="text-name">{{ column.valueGetterFn(row) }}</span>
            } @if (column?.truncate) {
            <span [ngClass]="column.dataClass" class="text-name">{{ column.valueGetterFn(row) | truncate }}</span>
            } }
            <!-- Type Column: [Dynamic Text] End -->
            <!-- Type Column: [Number] Start -->
            @case ('number') {
            <span [ngClass]="column.dataClass" class="text-name"
              >{{ row[column.key] | egNumber: column.numberFormat }}{{ column.textSuffix }}</span
            >
            }
            <!-- Type Column: [Number] End -->
            <!-- Type Column: [Date] Start -->
            @case ('date') {
            <span [ngClass]="column.dataClass" class="text-name">{{ row[column.key] | egDate: column.dateFormat }}</span>
            }
            <!-- Type Column: [Date] End -->
            <!-- Default Column Type: [Text] start -->
            @default { @if (!column?.truncate) {
            <span [ngClass]="column.dataClass" class="text-name">{{ row[column.key] }}{{ column.textSuffix }}</span>
            } @if (column?.truncate) {
            <span [ngClass]="column.dataClass" class="text-name">{{ row[column.key] | truncate }}{{ column.textSuffix }}</span>
            } } }
            <!-- Default Column Type: [Text] end -->
          </span>
        </div>
        } @if (expandable && row?.contacts?.length) {
        <div
          fxLayoutAlign="right center"
          class="table__data-cell table__data-cell--actions text-align-center expandable__header--icon"
          fxFlex
          (click)="toggleExpansion($event, idx)"
        >
          <svg-icon src="/assets/icons/dropdown_{{ expanded === idx ? 'close' : 'open' }}.svg"></svg-icon>
        </div>
        }
      </div>
      @if ((!expandable && row.contacts.length > 0) || expanded === idx) {
      <div class="expandable__body subtable" fxLayout="column" [@slide]>
        <ng-container>
          @for (contact of row.contacts; track contact) {
          <div fxFlex="100" fxLayout="row wrap" class="table__row py-8 mb-10" [ngClass]="contact[rowClassField] | lowercase">
            <div fxFlex="100" fxLayoutAlign="start center" fxLayoutGap="4px" (click)="rowSelected(contact)">
              @for (column of contactColumns; track column) {
              <div class="table__data-cell" [ngClass]="column.cellClass" [fxFlex]="column.flex">
                <span [egTooltip]="column.tooltipGetterFn ? column.tooltipGetterFn(contact) : null">
                  @switch (column.columnType) {
                  <!-- Type Column: [Template] Start -->
                  @case ('template') {
                  <ng-container *ngTemplateOutlet="column.template; context: { entity: contact }"></ng-container>
                  }
                  <!-- Type Column: [Template] End -->
                  <!-- Type Column: [Dynamic Text] Start -->
                  @case ('dynamic-text') { @if (!column?.truncate) {
                  <span [ngClass]="column.dataClass" class="text-name">{{ column.valueGetterFn(contact) }}</span>
                  } @if (column?.truncate) {
                  <span [ngClass]="column.dataClass" class="text-name">{{ column.valueGetterFn(contact) | truncate }}</span>
                  } }
                  <!-- Type Column: [Dynamic Text] End -->
                  <!-- Type Column: [Number] Start -->
                  @case ('number') {
                  <span [ngClass]="column.dataClass" class="text-name"
                    >{{ contact[column.key] | egNumber: column.numberFormat }}{{ column.textSuffix }}</span
                  >
                  }
                  <!-- Type Column: [Number] End -->
                  <!-- Type Column: [Date] Start -->
                  @case ('date') {
                  <span [ngClass]="column.dataClass" class="text-name">{{ contact[column.key] | egDate: column.dateFormat }}</span>
                  }
                  <!-- Type Column: [Date] End -->
                  <!-- Default Column Type: [Text] start -->
                  @default { @if (!column?.truncate) {
                  <span [ngClass]="column.dataClass" class="text-name">{{ contact[column.key] }}{{ column.textSuffix }}</span>
                  } @if (column?.truncate) {
                  <span [ngClass]="column.dataClass" class="text-name">{{ contact[column.key] | truncate }}{{ column.textSuffix }}</span>
                  } } }
                  <!-- Default Column Type: [Text] end -->
                </span>
              </div>
              }
            </div>
          </div>
          }
        </ng-container>
      </div>
      }
    </div>
    } } @else {
    <ng-template [ngTemplateOutlet]="emptyTableTemplate || defaultEmptyTableTemplate"></ng-template>
    }
  </div>
</div>

<ng-template #defaultEmptyTableTemplate>
  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center">no data to display</div>
</ng-template>
