import { Component, Input, OnInit, ViewChild } from '@angular/core';
import dayjs from 'dayjs/esm';
import utc from 'dayjs/esm/plugin/utc';
import timezone from 'dayjs/esm/plugin/timezone';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AbstractDateComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { Calendar } from 'primeng/calendar';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface DateTimeValue {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
}

@UntilDestroy()
@Component({
  selector: 'eg-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(DateTimeComponent)],
})
export class DateTimeComponent extends AbstractDateComponent<DateTimeValue | string> implements OnInit {
  dateTime: Date;
  @ViewChild('dateTimePicker') dateTimePicker: Calendar;
  @Input() timeInterval = 5;

  get formattedValue(): string {
    const value = this.value as DateTimeValue;
    if (!value?.startDate) {
      return undefined;
    }
    return `${value.startDate.tz(dayjs.tz.guess(), true).format(this.localeFormat)} ${value.startDate
      .tz(dayjs.tz.guess(), true)
      .format('HH:mm')} `;
  }

  /**
   * emits selected interval
   * @param value
   */
  onInputChanged(): void {
    const values = {
      startDate: this.dateTime ? dayjs(this.dateTime.toISOString()) : undefined,
      endDate: undefined,
    };

    if (!values?.startDate) {
      return;
    }

    this.value = values;
    const start = dayjs(values.startDate).tz(dayjs.tz.guess(), true).toISOString();
    this.onChange(start);
    this.changed.emit(start);
    this.onTouched();
  }

  openDatePicker(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.control.disabled || this.disabled) {
      return;
    }
    this.dateTimePicker.toggle();
  }

  override writeValue(value: string): void {
    if (!value) {
      super.writeValue(undefined);
      return;
    }
    const parsedDate = dayjs(value).tz(dayjs.tz.guess(), true);
    this.dateTime = dayjs(value).toDate();

    super.writeValue({
      startDate: parsedDate.isValid() ? parsedDate : undefined,
      endDate: parsedDate.isValid() ? parsedDate : undefined,
    });
  }

  override setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    if (isDisabled === this.control.disabled) {
      return;
    }

    const disableFn = isDisabled ? 'disable' : 'enable';
    this.control[disableFn]();
  }
}
