import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '@eg/ui';
import { CapacityPillComponent } from './capacity-pill.component';
import { SharedPipesModule } from '@railmybox/shared/pipes';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [CapacityPillComponent],
  imports: [CommonModule, UiModule, SharedPipesModule, TranslocoModule],
  exports: [CapacityPillComponent],
})
export class CapacityPillModule {}
