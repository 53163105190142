import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { resendSignUpCode, confirmSignUp } from '@aws-amplify/auth';
import { Router } from '@angular/router';
import { interval, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserQuery } from '../../store/user';

@Component({
  selector: 'rmb-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
  standalone: false,
})
export class EmailVerificationComponent implements OnInit {
  readonly TRANSLOCO_PREFIX = 'auth.verify.';
  registrationEmail: string;
  counter$: Observable<number>;
  codeForm: FormGroup = new FormGroup({
    code: new FormControl<string>('', [Validators.required]),
  });
  responseError: string;
  infoMsg: string;

  constructor(private router: Router, private userQuery: UserQuery) {}

  async ngOnInit(): Promise<void> {
    this.registrationEmail = this.userQuery.getUserEmail();
    if (!this.registrationEmail) {
      console.error('Cannot Verify user without email.');
      await this.router.navigate(['/auth', 'login']);
    }
  }

  async resendCode(): Promise<void> {
    this.responseError = null;
    try {
      await resendSignUpCode({ username: this.registrationEmail });
      this.infoMsg = this.TRANSLOCO_PREFIX + 'resent';
      this.counter$ = this.getCounter(10);

      this.executeAfter(10000, () => {
        this.infoMsg = null;
      });
    } catch (err) {
      this.codeForm.get('code').setValue(undefined);
      this.responseError = this.getResponseError(err);
    }
  }

  async onCodeSubmit(): Promise<void> {
    if (this.codeForm.invalid) {
      return;
    }

    try {
      await confirmSignUp({
        username: this.registrationEmail,
        confirmationCode: this.codeForm.value.code,
        options: {
          forceAliasCreation: true,
        },
      });
      this.infoMsg = this.TRANSLOCO_PREFIX + 'success';

      this.executeAfter(5000, async () => {
        await this.router.navigate(['/auth/login']);
      });
    } catch (err) {
      this.responseError = this.getResponseError(err);
    }
  }

  private getResponseError(err: unknown): string {
    return typeof err === 'string' ? err : (err as Record<string, string>).message;
  }

  private getCounter(count: number): Observable<number> {
    return interval(1000).pipe(
      map(() => (count = count - 1)),
      take(count)
    );
  }

  private executeAfter(time: number, callback: () => void | Promise<void>): void {
    interval(time).pipe(take(1)).subscribe(callback);
  }
}
