import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractDateComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DatePicker } from 'primeng/datepicker';
import utc from 'dayjs/esm/plugin/utc';
import timezone from 'dayjs/esm/plugin/timezone';
import dayjs from 'dayjs/esm';

dayjs.extend(utc);
dayjs.extend(timezone);

@UntilDestroy()
@Component({
  selector: 'eg-month',
  templateUrl: './month.component.html',
  styleUrl: './month.component.scss',
  providers: [...ValueAccessorUtil.getValueAccessorProvider(MonthComponent)],
  standalone: false,
})
export class MonthComponent extends AbstractDateComponent<{ endDate: any; startDate: any }> implements OnInit {
  enabledDates: Date[] = [];
  disabledDates: Date[] = [];
  monthValue: Date;

  @ViewChild('month') month: DatePicker;
  @Input() postingMonths = [];

  get formattedValue(): string {
    if (!this.value?.startDate) {
      return undefined;
    }
    const values = { startDate: this.value.startDate };
    return `${dayjs(values.startDate).format(this.monthRange)}`;
  }

  onSelect(value: string): void {
    const date = { startDate: value, endDate: null };

    if (!date) {
      return;
    }
    this.value = date;
    this.onChange(this.getDayValues(value));
    this.changed.emit(this.getDayValues(value));
    this.onTouched();
  }

  openDatePicker(event: MouseEvent): void {
    if (this.control?.disabled || this.disabled) {
      return;
    }

    if (this.postingMonths) {
      this.generateEnabledDates();
      this.generateDisabledDates();
    }

    this.month.toggle();
    event.stopPropagation();
    event.preventDefault();
  }

  override writeValue(value: any): void {
    const date = { startDate: value, endDate: null };
    if (value) {
      super.writeValue(date);
      this.monthValue = this.monthDisplay(value);
    }
    if (value === null) {
      super.writeValue(date);
      this.month.clear();
    }
  }

  override setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    if (isDisabled === this.control.disabled) {
      return;
    }
    const disableFn = isDisabled ? 'disable' : 'enable';
    this.control[disableFn]({ emitEvent: false });
  }

  getDayValues(value: string): any {
    return dayjs(value).format('YYYY-MM-01');
  }

  generateEnabledDates() {
    this.enabledDates = this.postingMonths.flatMap((item) => {
      const [year, month] = item.postingMonth.split('-').map(Number);
      return this.generateDates(year, month - 1);
    });
  }

  generateDisabledDates() {
    const allDates = this.getAllDates();
    const enabledDateSet = new Set(this.enabledDates.map((date) => date.getTime()));
    this.disabledDates = allDates.filter((date) => !enabledDateSet.has(date.getTime()));
  }

  generateDates(year: number, month: number): Date[] {
    const numDays = new Date(year, month + 1, 0).getDate();
    return Array.from({ length: numDays }, (_, index) => new Date(year, month, index + 1));
  }

  getAllDates(): Date[] {
    const startYear = dayjs(this.dateMin).year();
    const endYear = dayjs(this.dateMax).year();

    return Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i)
      .flatMap((year) => Array.from({ length: 12 }, (_, month) => this.generateDates(year, month)))
      .flat();
  }

  monthDisplay(date: string) {
    const dateObj = dayjs(date);
    const selectedMonth = new Date(dateObj.year(), dateObj.month(), 1);
    return selectedMonth;
  }
}
