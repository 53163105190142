<div class="date-container">
  <eg-input
    class="ngx-daterangepicker-action"
    [value]="formattedValue"
    [label]="labelWithSuffix"
    [name]="name"
    [placeholder]="placeholder"
    [readonly]="true"
    [disabled]="disabled"
    (click)="openDatePicker($event)"
    (blurred)="markInputAsTouched()"
  >
    <img egFieldSuffixDirective src="/assets/icons/field_date.svg" class="date-icon ngx-daterangepicker-action" />
  </eg-input>

  <p-calendar
    #datePicker
    class="p-calendar"
    [(ngModel)]="date"
    inputId="dateInput"
    [minDate]="minDateFormatted"
    [maxDate]="maxDateFormatted"
    [readonlyInput]="true"
    [placeholder]="placeholder"
    dateFormat="dd/mm/yy"
    (onSelect)="onInputChanged()"
    [defaultDate]="defaultDate"
  >
  </p-calendar>
  @if (control?.touched && control?.errors && errorMessage) {
  <eg-form-error [errorMessage]="errorMessage" class="eg-input__error"></eg-form-error>
  }
</div>
