import { Pipe, PipeTransform } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';

@Pipe({
  name: 'egCurrencySymbol',
  standalone: false,
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(value: string, format: 'wide' | 'narrow' = 'wide'): string {
    if (!value) {
      return '';
    }

    return getCurrencySymbol(value, format);
  }
}
