import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { FlexModule } from '@ngbracket/ngx-layout';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ModalModule } from '@eg/ui';
import { UnsavedChangesWarningComponent } from './unsaved-changes-warning.component';

@NgModule({
  declarations: [UnsavedChangesWarningComponent],
  imports: [CommonModule, TranslocoModule, FlexModule, AngularSvgIconModule.forRoot(), ModalModule],
  exports: [UnsavedChangesWarningComponent],
})
export class UnsavedChangesModule {}
