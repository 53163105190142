import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionMenuItem } from '../../menu.interface';

@Component({
  selector: 'eg-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
  standalone: false,
})
export class ActionMenuComponent {
  @Input() actionEntity: any;
  @Input() menuItems: ActionMenuItem[] = [];
  displayMenuDropDown = false;
  @Output() menuOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleMenuDisplay(): void {
    this.displayMenuDropDown = !this.displayMenuDropDown;
    this.menuOpen.emit(this.displayMenuDropDown);
  }

  closeActionMenu(): void {
    this.displayMenuDropDown = false;
    this.menuOpen.emit(this.displayMenuDropDown);
  }
}
