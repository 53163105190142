import { NgModule } from '@angular/core';
import { InputModule } from './input';
import { FormErrorModule } from './form-error';
import { SelectModule } from './select';
import { ButtonModule } from './button';
import { NumberSelectionModule } from './number-selection';
import { AlertModule } from './alert';
import { DateRangeModule } from './date-range';
import { CheckboxModule } from './checkbox';
import { BreadcrumbModule } from './breadcrumb';
import { TableModule } from './table';
import { TooltipModule } from './tooltip';
import { DateTimeModule } from './date-time';
import { TimeRangePickerModule } from './time-range';
import { ModalModule } from './modal';
import { TabGroupModule } from './tab-group';
import { DateModule } from './date';
import { FileUploadModule } from './file-upload';
import { DropdownModule } from './dropdown';
import { UploadModule } from './upload';
import { ExpandableModule } from './expandable';
import { SpinnerModule } from './spinner';
import { ConfirmationModule } from './confirmation';
import { MultiselectModule } from './multiselect';
import { ReadonlyTextModule } from './reaonly-text';
import { ToggleModule } from './toggle';
import { PillModule } from './pill';
import { TextareaModule } from './textarea';
import { TimeModule } from './time';
import { MonthRangeModule } from './month-range';
import { MonthModule } from './month';
import { TextEditorModule } from './text-editor';

@NgModule({
  exports: [
    AlertModule,
    BreadcrumbModule,
    ButtonModule,
    CheckboxModule,
    ConfirmationModule,
    DateModule,
    DateRangeModule,
    DateTimeModule,
    DropdownModule,
    TextEditorModule,
    ExpandableModule,
    FileUploadModule,
    FormErrorModule,
    InputModule,
    ModalModule,
    MonthModule,
    MonthRangeModule,
    MultiselectModule,
    NumberSelectionModule,
    PillModule,
    ReadonlyTextModule,
    SelectModule,
    SpinnerModule,
    TabGroupModule,
    TableModule,
    TimeRangePickerModule,
    ToggleModule,
    TooltipModule,
    UploadModule,
    TextareaModule,
    TimeModule,
  ],
})
export class UiModule {}
