/// <reference types="@types/googlemaps" />
import { Component, Renderer2 } from '@angular/core';
import { FreshchatService } from './services/freshchat.service';
import { environment } from '../environments/environment';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'railmybox-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent {
  title = 'railMybox';
  favicon: HTMLLinkElement = document.querySelector('#appFavicon') as HTMLLinkElement;

  constructor(private freshchatService: FreshchatService, private renderer: Renderer2) {
    this.updateIcon();
  }

  private updateIcon(): void {
    if (environment.apiBasePath.includes('dev.')) {
      this.favicon?.setAttribute('href', 'assets/icons/favicon_dev.ico');
    } else if (environment.apiBasePath.includes('test.')) {
      this.favicon?.setAttribute('href', 'assets/icons/favicon_test.ico');
    }
  }
}
