import { MenuItem } from 'primeng/api';

export const supportMenuItems: MenuItem[] = [
  {
    label: '.helpdesk',
    icon: 'assets/icons/dispo.svg',
    route: ['/', 'support', 'disposition'],
  },
  {
    label: '.train-management',
    icon: 'assets/icons/train-visits.svg',
    items: [
      { label: '.trainSchedule', routerLink: ['/', 'support', 'train-visits'] },
      { label: '.capacity-management', routerLink: ['/', 'support', 'capacity-management'] },
    ],
  },
  {
    label: '.wishlist',
    icon: 'assets/icons/wishlist_nav_admin.svg',
    route: ['/', 'support', 'wishlist'],
  },
  {
    label: '.pricing',
    icon: 'assets/icons/money-check-alt.svg',
    items: [
      { label: '.tariff-scales', routerLink: ['/', 'support', 'tariff-scales'] },
      { label: '.basis-zones', routerLink: ['/', 'support', 'basis-zones'] },
      { label: '.tariff-zones', routerLink: ['/', 'support', 'tariff-zones'] },
      { label: '.customer-tariffs', routerLink: ['/', 'support', 'customer-tariffs'] },
      { label: '.customer-discounts', routerLink: ['/', 'support', 'customer-discount'] },
    ],
  },
  {
    label: '.accounting',
    icon: 'assets/icons/calculator.svg',
    items: [
      { label: '.posting-months', routerLink: ['/', 'support', 'posting-months'] },
      { label: '.incoming-costs', routerLink: ['/', 'support', 'incoming-costs'] },
      { label: '.summed-costs', routerLink: ['/', 'support', 'summed-costs'] },
      { label: '.summed-reserves', routerLink: ['/', 'support', 'summed-reserves'] },
      { label: '.incoming-invoices', routerLink: ['/', 'support', 'incoming-invoices'] },
    ],
  },
  {
    label: '.customers',
    icon: 'assets/icons/customer-info.svg',
    items: [
      { label: '.customers', routerLink: ['/', 'support', 'customers'] },
      { label: '.customer-groups', routerLink: ['/', 'support', 'customer-groups'] },
    ],
  },
  {
    label: '.invoices',
    icon: 'assets/icons/invoices.svg',
    route: ['/', 'support', 'invoices'],
  },
  {
    label: '.masterdata.title',
    icon: 'assets/icons/project-diagram.svg',
    items: [
      { label: '.masterdata.trains', routerLink: ['/', 'support', 'trains'] },
      { label: '.masterdata.services', routerLink: ['/', 'support', 'services'] },
      { label: '.masterdata.isoCodes', routerLink: ['/', 'support', 'iso-codes'] },
      { label: '.masterdata.stations', routerLink: ['/', 'support', 'stations'] },
      { label: '.masterdata.ports', routerLink: ['/', 'support', 'ports'] },
      { label: '.masterdata.operators', routerLink: ['/', 'support', 'operators'] },
      { label: '.masterdata.depots', routerLink: ['/', 'support', 'depots'] },
      { label: '.masterdata.shippingLine', routerLink: ['/', 'support', 'shipping-line'] },
      { label: '.masterdata.tollBases', routerLink: ['/', 'support', 'toll-bases'] },
      { label: '.masterdata.currency', routerLink: ['/', 'support', 'currencies'] },
    ],
  },
  {
    label: '.news',
    icon: 'assets/icons/megaphone.svg',
    route: ['/', 'support', 'news'],
  },
];
