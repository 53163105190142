import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TableNotFoundComponent } from './table-not-found.component';

@NgModule({
  declarations: [TableNotFoundComponent],
  imports: [CommonModule, TranslocoModule, FlexLayoutModule],
  exports: [TableNotFoundComponent],
})
export class TableNotFoundModule {}
